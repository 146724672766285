import {
  DashboardOutlined,
  Group,
  GroupAddOutlined,
  ExtensionTwoTone,
  Copyright,
  BookmarkBorderOutlined,
  EditAttributesTwoTone,
  SportsEsportsOutlined,
  PolicyOutlined,
  AddIcCallOutlined,
  HelpOutline,
  CurrencyRupee,
  NotificationImportantOutlined,
  AppsOutlined,
} from "@material-ui/icons";
import React, { Component } from "react";

import Dashboard from "./App/Main/Dashboard";

import Contact from "./App/Main/Contact";
import Hiring from "./App/Main/Hiring";
import Carrier from "./App/Main/Carrier";
import Testimonial from "./App/Main/Testimonial";

import OurWork from "./App/Main/OurWork";
import OurWorkApp from "./App/Main/OurWorkApp";
import OurGallery from "./App/Main/OurGallery";
import Training from "./App/Main/Training";

export const routes = {
  Contact: [
    {
      title: "Contact US",
      path: "/contactUS",
      component: Contact,
      icon: <AddIcCallOutlined />,
      exact: true,
      pathId: "",
    },
  ],

  Hiring: [
    {
      title: "Hiring",
      path: "/hirings",
      component: Hiring,
      icon: <Group />,
      exact: true,
      pathId: "",
    },
  ],
  Carriers: [
    {
      title: "Careers",
      path: "/carriers",
      component: Carrier,
      icon: <Copyright />,
      exact: true,
      pathId: "",
    },
  ],

  Testimonials: [
    {
      title: "Testimonial",
      path: "/testimonials",
      component: Testimonial,
      icon: <GroupAddOutlined />,
      exact: true,
      pathId: "",
    },
  ],

  OURWEB: [
    {
      title: "Our Work Web",
      path: "/ourwork",
      component: OurWork,
      icon: <DashboardOutlined />,
      exact: true,
      pathId: "",
    },
  ],

  OURAPP: [
    {
      title: "Our Work App",
      path: "/ourworkapp",
      component: OurWorkApp,
      icon: <DashboardOutlined />,
      exact: true,
      pathId: "",
    },
  ],

  GALLARY: [
    {
      title: "Our Gallery",
      path: "/ourgallery",
      component: OurGallery,
      icon: <AppsOutlined />,
      exact: true,
      pathId: "",
    },
  ],
  TRAINIGS: [
    {
      title: "Trainings",
      path: "/trainings",
      component: Training,
      icon: <AppsOutlined />,
      exact: true,
      pathId: "",
    },
  ],
};
