// const mainDomain = "http://localhost:6300/"
const mainDomain = "https://core.thespaceelement.com/";

const Constants = {
  getUrls: {
    //get api's for TSE backend

    getAllHirings: mainDomain + "hiring",
    getHiringById: mainDomain + "hiring/getSingle/",

    getAllContacts: mainDomain + "contacts",

    getAllCarrier: mainDomain + "carrier",

    getAllTestimonial: mainDomain + "testimonial",
    getTestimonialById: mainDomain + "testimonial/getSingleData/",

    getAllOurWorks: mainDomain + "workweb",
    getOurWorkById: mainDomain + "workweb/getSingleData/",

    getAllOurWorksApp: mainDomain + "workapp",

    getAllGalleryImg: mainDomain + "gallery",

    getAllTraining: mainDomain + "trainings",
    getOurTrainingById: mainDomain + "trainings/getSingleData/",
  },
  postUrls: {
    login: mainDomain + "admin/login",

    //post API's of TSE
    createHirings: mainDomain + "hiring/create",
    createContact: mainDomain + "contacts/create",
    createCarrier: mainDomain + "carrier/create",
    createTestimonial: mainDomain + "testimonial/create",
    createOurWorksWeb: mainDomain + "workweb/create",
    createOurWorksApp: mainDomain + "workapp/create",
    createOurGallery: mainDomain + "gallery/create",
    createTraining: mainDomain + "trainings/create",

    //delete api's of TSE
    deleteHirings: mainDomain + "hiring/delete/",
    deleteContacts: mainDomain + "contacts/delete/",
    deleteCarriers: mainDomain + "carrier/delete/",
    deleteTestimonials: mainDomain + "testimonial/delete/",
    deleteOurWorks: mainDomain + "workweb/delete/",
    deleteOurWorksApp: mainDomain + "workapp/delete/",
    deleteGalleryImgs: mainDomain + "gallery/delete/",
    deleteTraining: mainDomain + "trainings/delete/",
  },
  putUrls: {
    //update api's of TSE
    updateHiring: mainDomain + "hiring/update/",
    UpdateTestimonial: mainDomain + "testimonial/update/",
    updateOurWork: mainDomain + "workweb/update/",
    updateTraining: mainDomain + "trainings/update/",
  },
};
export default Constants;
