import {
  Backdrop,
  Button,
  Container,
  Select,
  InputLabel,
  Fade,
  FormControl,
  Grid,
  // Modal,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import "../../App.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // Table,
  Row,
  Col,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Modal from "react-awesome-modal";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import Constants from "../Variables/Constants";
import init from "../../helpers/WindowToken";
import "../../App.css";

export default class Training extends Component {
  state = {
    allOurTraining: [],
    title: "",
    // description: "",
    // file: "",

    rowId: "",
    visible: false,
    loading: false,
    deleteOurTraining: false,
    notifyDelete: false,
    delMsg: "",
    delLoading: false,
  };

  componentDidMount() {
    this.getTraining();
  }

  getTraining = () => {
    //   if ( init() === "success" ){
    axios.get(Constants.getUrls.getAllTraining).then((resp) => {
      console.log(resp);
      this.setState(
        {
          allOurTraining: resp.data.data.docs,
          // loading: true
        },
        () => {
          console.log(this.state.allOurTraining);
        }
      );
    });
    //   }
  };

  // handleChange = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //     },
  //     () => {
  //       if (this.state.search.length >= 3 || this.state.search.length === 0) {
  //         this.getTraining();
  //       }
  //     }
  //   );
  // };

  handleModal = (ourtraining) => {
    this.setState(
      {
        rowId: ourtraining._id,
        visible: true,
      },
      () => {
        console.log(this.state.rowId);
      }
    );
  };

  handleDelete = () => {
    this.setState({
      delLoading: true,
    });
    let payload = {
      _id: this.state.rowId,
    };

    if (init() == "success") {
      // this.setState({ delLoading: true });
      axios
        .delete(Constants.postUrls.deleteTraining + this.state.rowId, payload)
        .then((res) => {
          console.log(res);
          this.setState({
            deleteOurTraining: true,
            delLoading: false,
            delMsg: res.data.message,
          });
          setTimeout(() => {
            this.setState({ deleteOurTraining: false, visible: false }, () => {
              this.getTraining();
            });
          }, 2000);
        });
    }
  };

  render() {
    return (
      <Container maxWidth="xl">
        <Grid container spcaing={2}>
          <Grid item sm={6}>
            <Typography variant="h4">Our Trainings</Typography>
          </Grid>
          <Grid container justify="flex-end">
            {/* <FormControl >
                            <TextField
                                id="standard-basic"
                                label="Search Coupons"
                                variant="standard"
                                size="small"
                                name="search"
                                onChange={this.handleChange}
                            />
                        </FormControl> */}
            {/* { this.state.agentProfile.role === "agent" ? null : ( */}
            <Link to="/admin/addTraining" style={{ textDecoration: "none" }}>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => {
                  this.setState({ open: true });
                }}
              >
                Add Our Training
              </Button>
            </Link>
            {/* )} */}
          </Grid>
        </Grid>

        <Grid sm={12}>
          <Paper elevetion={1}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>SR.NO</TableCell> */}

                    <TableCell>Title</TableCell>
                    {/* <TableCell>Description</TableCell> */}
                    {/* <TableCell>File</TableCell> */}
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.allOurTraining.length > 0 ? (
                    this.state.allOurTraining.map((ourtraining) => (
                      <TableRow>
                        <TableCell> {ourtraining.title}</TableCell>
                        {/* <TableCell> {ourtraining.description}</TableCell> */}
                        {/* <TableCell> {ourtraining.file}</TableCell> */}

                        <TableCell>
                          <Link
                            to={{
                              pathname:
                                "/admin/updateTraining/" + ourtraining._id,
                              state: { data: ourtraining },
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              variant={"outlined"}
                              color="primary"
                              // style={{ marginRight: 4, marginBottom: "8px" }}
                              style={{ marginRight: 5 }}
                              size={"small"}
                            >
                              Update
                            </Button>
                          </Link>
                          <Button
                            size={"small"}
                            variant={"outlined"}
                            color="secondary"
                            // style={{ marginRight: 4, marginBottom: "8px" }}
                            style={{ marginRight: 5 }}
                            onClick={() => {
                              this.handleModal(ourtraining);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>NO DATA FOUND</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Modal
          visible={this.state.visible}
          onClickAway={() => this.setState({ visible: false })}
          width="450px"
          height="80px"
          effect="fadeInUp"
        >
          <Row>
            <Col xs={12} xm={12} md={12}>
              <Card>
                <CardBody>
                  <div className="modal">
                    <h3 classname="changeColor" style={{ color: "black" }}>
                      {" "}
                      Are you sure you want to Delete??
                    </h3>

                    {this.state.deleteOurTraining ? (
                      <Snackbar
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "bottom",
                        }}
                        open={true}
                        autoHideDuration={5000}
                        color={"s"}
                        message="data deleted Successfully"
                      ></Snackbar>
                    ) : null}

                    <Row>
                      <Col xs={12} xm={12} md={4}>
                        <br />
                        <Button
                          className=""
                          color="secondary"
                          variant="contained"
                          style={{ margin: "20px" }}
                          onClick={() => {
                            this.handleDelete(this.state.rowId);
                          }}
                        >
                          {this.state.deleteOurTraining
                            ? "Deleting our Training"
                            : "Yes"}
                        </Button>
                        <Button
                          className=""
                          variant="contained"
                          color="primary"
                          style={{ margin: "20px", marginLeft: "4px" }}
                          onClick={() => {
                            this.setState({ visible: false });
                          }}
                        >
                          no
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Modal>
      </Container>
    );
  }
}
