import {
    Backdrop,
    Button,
    Container,
    Select,
    InputLabel,
    Fade,
    FormControl,
    Grid,
    // Modal,
    Paper,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    FormControlLabel,
  } from "@material-ui/core";
  import "../../App.css";
  import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    // Table,
    Row,
    Col,
    UncontrolledAlert,
    Pagination,
    PaginationItem,
    PaginationLink,
    FormGroup,
  } from "reactstrap";
  import Modal from "react-awesome-modal";
  import renderHTML from "react-render-html";
  import { Link } from "react-router-dom";
  import axios from "axios";
  import React, { Component } from "react";
  import Constants from "../Variables/Constants";
  import init from "../../helpers/WindowToken";
  import "../../App.css";

  export default class OurGallery extends Component{

    state={
        allGallery: [],
        search: "",
        TSEgallery: "",
        rowId: "",
        visible: false,
        loading: false,
        deleteGallery: false,
        notifyDelete: false,
        delMsg: "",
        delLoading: false,
    }

    componentDidMount(){
        this.getGallery()
    }

    getGallery = ()=>{
    //   if ( init() === "success" ){
        axios.get( Constants.getUrls.getAllGalleryImg )
        .then((resp)=>{
            console.log(resp);
            this.setState({
                allGallery: resp.data.data,
                // loading: true
            }, ()=>{console.log(this.state.allGallery)})
        })
    //   }
        
    }
//  search start
    handleChange =(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        }, ()=>{
            if( this.state.search.length >=3 || this.state.search.length === 0){
                this.getGallery()
            }
        })
    }
    // search ends

    // delete starts
    handleModal =(gallery)=>{
        this.setState({
          rowId: gallery._id,
          visible: true
        })
      }
      
    handleDelete = () => {
        this.setState({
          delLoading: true
        })
        let payload = {
          _id: this.state.rowId
        }
  
        if (init() == "success") {
          // this.setState({ delLoading: true });
          axios.delete(Constants.postUrls.deleteGalleryImgs + this.state.rowId).then((res) => {
            console.log(res);
            this.setState({
                deleteGallery: true,
              delLoading: false,
              delMsg:res.data.message
            });
            setTimeout(() => {
              this.setState({ deleteGallery: false, visible: false }, () => {
                this.getGallery();
              });
            }, 2000);
          });
        }
      };

    render(){
        return(
            <Container maxWidth="xl">
                <Grid container spcaing={2}>
                    <Grid item sm={6}>
                      <Typography variant="h4">Our Gallery</Typography>
                    </Grid>
                    <Grid container justify="flex-end">
                        {/* <FormControl >
                           
                        </FormControl> */}
                        {/* { this.state.agentProfile.role === "agent" ? null : ( */}
                                <Link
                                to="/admin/addgallery"
                                style={{ textDecoration: "none"}}
                                >
                                    <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={()=>{
                                        this.setState({ open: true})
                                    }}
                                    >
                                        Create Gallery
                                    </Button>
                                </Link>
                            {/* )} */}
                    </Grid>
                  
                </Grid>

                <Grid sm={12}>
                  <Paper elevetion={1}>
                    <TableContainer>
                        <Table size="small" stickyHeader aria-label="a dense table">
                         <TableHead>
                            <TableRow>
                                {/* <TableCell>S.No</TableCell> */}
                                <TableCell>Gallary Images</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                         </TableHead>
                         
                         <TableBody>
                            { this.state.allGallery.length > 0 ?
                                (this.state.allGallery.map((gallery)=>(
                                    <TableRow  >

                    <TableCell>{gallery.TSEgallery.map(image => <img src={`https://core.thespaceelement.com/${image}`} alt="a" width="100vw" height="100vh"/> )}</TableCell>
                                       
                                        <TableCell>
                                            {/* <Link
                                                to={{
                                                pathname: "/admin/updatecoupons/" + gallery._id,
                                                state: { data: gallery },
                                                }}
                                                style={{textDecoration:"none"}}
                                             >
                                            <Button
                                                variant={"outlined"}
                                                color="primary"
                                                style={{ marginRight: 4, marginBottom: "8px" }}
                                                size={"small"}
                                              >
                                                Update
                                            </Button>
                                                </Link> */}
                                            <Button
                                                size={"small"}
                                                variant={"outlined"}
                                                color="secondary"
                                                onClick={() => {
                                                   this.handleModal(gallery)
                                                }}
                                                 >
                                                Delete
                                            </Button>
                                        </TableCell>

                                    </TableRow>)
                                ))
                                :(
                                    <TableRow>
                                        <TableCell>
                                            NO DATA FOUND
                                        </TableCell>
                                    </TableRow>
                                )
                            }                             
                         </TableBody>
                     
                        </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Modal
                 visible={this.state.visible}
                 onClickAway={()=> this.setState({ visible: false})}
                 width="450px"
                 height="80px"
                 effect="fadeInUp"
                 >
                     <Row>
                         <Col xs={12} xm={12} md={12}>
                          <Card>
                              <CardBody>
                                  <div className="modal">
                                      <h3 
                                        classname="changeColor" 
                                        style={{color: "black"}}
                                       > Are you sure you want to Delete??</h3>
                                      
                                      {this.state.deleteGallery ? (
                                          <Snackbar
                                           anchorOrigin={{
                                               vertical: "bottom",
                                               horizontal: "bottom"
                                           }}
                                           open={true}
                                           autoHideDuration={5000}
                                           color={"s"}
                                           message="Imgaes deleted Successfully"
                                           >
                                              
                                          </Snackbar>
                                      ) : null}

                                      <Row>
                                          <Col xs={12} xm={12} md={4}>
                                           <br />
                                           <Button 
                                                className=""
                                                color="secondary"
                                                variant="contained"
                                                style={{margin : "20px"}}
                                                onClick={() => {
                                                this.handleDelete(this.state.rowId)
                                                }}
                                                >
                                                {this.state.deleteGallery ? "Deleting Gallery images" : "Yes"}
                                           </Button>
                                           <Button
                                                 className=""
                                                 variant="contained"
                                                 color="primary"
                                                 style={{ margin: "20px", marginLeft: "4px"}}
                                                 onClick={()=>{
                                                     this.setState({ visible: false})
                                                 }}
                                                >
                                                 no
                                                </Button>
                                          </Col>
                                      </Row>
                                  </div>
                              </CardBody>
                          </Card>
                         </Col>
                     </Row>
                </Modal>

                
                
            </Container>
        )
    }

  }
