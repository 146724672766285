import { CircularProgress, Typography } from "@material-ui/core";
import React, { Component } from "react";
import init from "../../helpers/WindowToken";

export default class AuthLoading extends Component {
  componentDidMount() {
    setTimeout(() => {
      if (init() === "success") {
        this.props.checkAuth(true);
      } else {
        this.props.checkAuth(false);
      }
    }, 2000);
  }

  render() {
    return (
      <div style={{ background: "#fff" }}>
        <div className={"authloaderWrapper"}>
          <CircularProgress
            color={"secondary"}
            className="authLoader"
            size={20}
          />
          <Typography variant="h6" component="h2" className={"authText"}>
            Authenticating...
          </Typography>
        </div>
      </div>
    );
  }
}
