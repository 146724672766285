import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
// import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Box,
//   GridItem,
  Modal,
  TextField,
  Typography,
  Snackbar,
//   InputLabel,
//   Select,
//   MenuItem,
} from "@material-ui/core";
import init from "../helpers/WindowToken";
import Constants from "../App/Variables/Constants";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { makeStyles } from "@material-ui/core";



const classes = makeStyles();

  export default class AddOurGallery extends Component{
    state={
        TSEgallery : [],
        loading: false,
   
    }

    handleSubmit = (e)=>{
        e.preventDefault();
        // let galleryLoad ={
        //     TSEgallery: this.state.TSEgallery,      
        // }
        // console.log(galleryLoad)

        let payload = new FormData();
      //  payload.append("TSEgallery" , this.state.TSEgallery);
      [...this.state.TSEgallery].forEach((image) => {
        payload.append("TSEgallery" , image);
      })
        console.log(payload)

        this.setState({
            loading: true
        })
        if( init() === "success"){
            axios.post(Constants.postUrls.createOurGallery , payload)
            .then((resp)=>{
                console.log(resp)
                this.setState({
                    loading: false,
                    notification: true,
                    message: resp.Message,
                })
            })
            setTimeout(()=>{
                this.setState({
                    notification: false,
                    redirect: true
                })
            }, 1500)
            
        }
    }
    handleStart = (e)=>{
      this.setState({
        [e.target.name]: e.target.value + "T00:00:00.000Z",
      }, ()=>{console.log(this.state.startDate)});
    }
    handleEnd = (e)=>{
      this.setState({
        [e.target.name]: e.target.value + "T11:59:59.999Z",
      }, ()=>{console.log(this.state.endDate)});
    }
    handleInput = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };

    handleFile = (e) => {
      if(e.target.name === "TSEgallery"){
        this.setState({
          TSEgallery : e.target.files,
        })
      }
    }
      

    render(){
        if ( this.state.redirect){
            return <Redirect to="/ourgallery" />
        } else{
        return(
            <Container maxWidth="xl">
                <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
                    <Grid item xs={6}>
                      <Typography variant="h5"> Create Our Gallery</Typography>
                    </Grid>
                </Grid>

                {/* After Getting api add Snackbar notification here  */}
                { this.state.notification ? (
                   <Snackbar
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      open={true}
                      color={"s"}
                      autoHideDuration={6000}
                      message={this.state.message}
                    ></Snackbar>

                ): null}

                <div
                  style={{
                    background: "#5b5757",
                    padding: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    border: "0",
                    display: "flex",
                    position: "relative",
                    fontSize: ".875rem",
                    minWidth: "0",
                    wordWrap: " break-word",
                    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
                    marginTop: "30px",
                    borderRadius: "6px",
                    marginBottom: "30px",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                 >
                     <div
                      style={{
                        background:
                          "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                        boxShadow:
                          " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                        float: "left",
                        padding: "15px",
                        marginTop: "-43px",
                        marginRight: "15px",
                        borderRadius: "3px",
                        backgroundColor: "#999",
                        width: "6%",
                        height: "60px",
                        marginBottom: "25px",
                        textAlign: "center",
                      }}
                      >
                          <EditIcon />
                     </div>

                 <form onSubmit={this.handleSubmit} encType="multipart/form-data">

                    <Grid container sm={12} spacing={2}>
                      <Grid item sm={4}>
                        <TextField
                            type="file"
                            id="outlined-basic"
                            onChange={this.handleFile}
                            label="TSE Gallery"
                            name="TSEgallery"
                            variant="outlined"
                            inputProps={{
                              multiple : true
                            }}
                            required={true}

                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        {/* <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Experiance Level"
                            name="experiance_level"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Qualification"
                            name="qualification"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Job Description"
                            name="job_description"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Skillsets"
                            name="skillset"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Job Role"
                            name="role"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Functional Area"
                            name="functional_area"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid> */}

                      

                        {/* <div style={{ color: "black", marginLeft: "8px", width: "99%"}}>
                           <label style={{color: "white"}}> Description</label>
                            <CKEditor
                              editor={ClassicEditor}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                // console.log({ event, editor, data });
                                this.setState({
                                  description: data,
                                });
                              }}
                              data={this.state.description}
                            />
                          </div> */}
                        

                    </Grid> 
                         
                    <br/>
                    <div>
                    <Button
                            variant="contained"
                            type="submit"
                            size="small"
                            color="primary"
                            style={{marginRight: "20px", width: "15%", marginTop: "35px" }}
                            >
                            {this.state.loading ? "Adding.." : "Add Images in gallery"}
                        </Button>
                    <Link
                        to={{
                            pathname: "/ourgallery",
                        }}
                        style={{ textDecoration: "none" }}
                        >
                        <Button
                            variant="contained"
                            type="submit"
                            size="small"
                            color="secondary"
                            style={{ marginRight: "20px", marginTop: "35px" }}
                           >
                            Cancel
                        </Button>
                    </Link>
                    </div>
                 </form>
                     
                </div>

            </Container>
        )
    }
  }
  }