import { Grid } from "@material-ui/core";
import { Dashboard } from "@material-ui/icons";
import React, { Component } from "react";

import { Redirect, Route, Switch } from "react-router-dom";
import { routes } from "../../routes";

import AuthLoading from "../Auth/AuthLoading";
import MainAppbar from "./Layout/MainAppbar";
import Sidebar from "./Layout/Sidebar";

import axios from "axios";
import Constants from "../Variables/Constants";

// import AddContact from "../../AddViews/AddContacts";

import AddHirings from "../../AddViews/AddHirings";
import UpdateHirings from "../../UpdateViews/UpdateHirings";

import AddTestimonial from "../../AddViews/AddTestimonial";
import UpdateTestimonial from "../../UpdateViews/UpdateTestimonial";

// import AddCarrier from "../../AddViews/AddCarrier";

import AddOurWorks from "../../AddViews/AddOurWorks";
import UpdateOurWork from "../../UpdateViews/UpdateOurWork";

import AddOurWorkApp from "../../AddViews/AddOurWorkApp";

import AddOurGallery from "../../AddViews/AddOurGallery";
import AddTraining from "../../AddViews/AddTraining";
import UpdateTraining from "../../UpdateViews/UpdateTraining";

export default class Main extends Component {
  state = {
    loading: true,
    authenticated: false,
    role: "agent",
    profileData: [],
  };

  checkAuth = (status) => {
    if (status) {
      // console.log(status)
      this.setState({ loading: false, authenticated: true });
      this.getProfile();
    } else {
      // this.props.history.push("/login");
      this.setState({ loading: false, authenticated: false });
    }
  };

  getProfile = () => {
    axios.get(Constants.getUrls.getProfile).then((res) => {
      // console.log(res.data);
      if (res.data.status === "success") {
        this.setState({
          profileData: res.data.user,
        });
      }
      // console.log(this.state.profileData.role);
    });
  };

  render() {
    return (
      <div>
        {this.state.loading ? <AuthLoading checkAuth={this.checkAuth} /> : null}
        {this.state.authenticated ? (
          <>
            <MainAppbar />

            <Grid container className="main">
              <Grid
                item
                style={{
                  position: "fixed",
                  width: "16.666667%",
                  marginTop: "70px",
                }}
                className="sidebar"
              >
                <Sidebar />
              </Grid>

              <Grid item xs={2} style={{ margin: "12px 0" }}></Grid>

              <Grid item xs={10} spacing={1} style={{ margin: "100px 0" }}>
                <Switch>
                  {/* <Switch defau> */}
                  {/* {routes.map((route, index) => (
                    <Route
                      exact={route.exact}
                      path={route.path}
                      // component={route.component} -- NOT WORKING
                      render={() => <route.component title={route.title} />}
                      key={index}
                    />
                  ))} */}

                  {routes.Contact.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}

                  {routes.Hiring.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}

                  {routes.Carriers.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}

                  {routes.Testimonials.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}

                  {routes.OURWEB.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}

                  {routes.OURAPP.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}

                  {routes.GALLARY.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}

                  {routes.TRAINIGS.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      // element={route.component}
                      render={() => <route.component title={route.title} />}
                      // component={route.component} -- NOT WORKING
                    />
                  ))}

                  <Route path="/admin/addhirings" component={AddHirings} />
                  <Route
                    path="/admin/updatehirings/"
                    component={UpdateHirings}
                  />

                  <Route
                    path="/admin/addtestimonial"
                    component={AddTestimonial}
                  />
                  <Route
                    path="/admin/updatetestimonial/"
                    component={UpdateTestimonial}
                  />

                  {/* <Route path="/admin/addcontact" component={AddContact} /> */}

                  <Route path="/admin/addOurWork" component={AddOurWorks} />

                  <Route path="/admin/addTraining" component={AddTraining} />

                  <Route
                    path="/admin/updateOurWorkWeb/"
                    component={UpdateOurWork}
                  />

                  <Route
                    path="/admin/updateTraining/"
                    component={UpdateTraining}
                  />

                  <Route
                    path="/admin/addOurWorkApp"
                    component={AddOurWorkApp}
                  />

                  <Route path="/admin/addgallery" component={AddOurGallery} />
                </Switch>
              </Grid>
            </Grid>
          </>
        ) : !this.state.loading ? (
          ""
        ) : // <Redirect to="/login" />
        null}
      </div>
    );
  }
}

// ) : !this.state.loading ? (
//   <Redirect to="/login" />
// ) : null}
