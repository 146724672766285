import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Logo from "../../Assets/Stocks10.svg"
import LOGOTSE from "../../Assets/tSe_logo 1.png"



import axios from "axios";
import { Link } from "react-router-dom";

import React, { Component } from "react";
import Constants from "../Variables/Constants";

export default class Login extends Component {
  state = {
    error: false,
    email: "",
    password: "",
    role:"admin"
  };

  login = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let loginLoad = {
      email: this.state.email,
      password: this.state.password,
      role:"admin"
    };
    console.log(loginLoad);
    axios.post(Constants.postUrls.login, loginLoad).then((resp) => {
      console.log(resp);
      
        localStorage.setItem("accessToken", resp.data.data.token);
        
      
        window.location.href = "/contactUS";
        
        this.setState({
          notification: true,
          loading: false,
        })

    
    }).catch(err => {
          if (err) {
            console.log(err);
            this.setState({
            loading: false,
            message: "Invalid Credentials",
            error: true,
          });
          setTimeout(() => {
            this.setState({
              error: false,
            });
            }, 3000);
          }
        });;
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <div className="loginWrapper">
        
        <div 
         style={{
           height: "100vh",
           backgroundColor: "#111",
           opacity: "0.8",
         }}
         >
           <img src={LOGOTSE}
            style={{
             marginTop: "10px",
             marginLeft: "10px",
              height: "120px",
              width: "120px",
            }}
            />
            {/* https://files.slack.com/files-pri/TNF0B8YSZ-F02ASNY85PG/image.png */}
            <div>
            <Container maxWidth="md">
          <Grid container>
            <Grid item sm={6} style={{ position: "relative", height: "80vh", marginTop: "-100px" }}>
              <Paper className="loginCard" elevation={15} style={{ height: "58vh" }}>
                <Typography variant="h4" component="h2" gutterBottom style={{color: "#64aa37"}}>
                  TSE Dashboard
                </Typography>
                <Typography variant="h5" component="h1">
                  Log in
                </Typography>
                <Typography
                  variant="body2"
                  component="h1"
                  gutterBottom
                  color="textSecondary"
                >
                  Continue to Admin dashboard
                </Typography>
                {/* <Typography
                  color="primary"
                  variant="body2"
                  style={{ alignSelf: "flex-end" , cursor:"pointer" }}
                >
                  Forgot password?
                </Typography> */}
                <form className="loginFormWrapper" onSubmit={this.login}>
                  <TextField
                    id="Outlined Success"
                    color="success"
                    
                    label="Registered Email ID"
                    variant="outlined"
                    fullWidth
                    name="email"
                    onChange={this.handleInput}
                   
                  />
                  <TextField
                    id="outlined-basic"
                    label="Password"       
                    variant="outlined"
                    size="large"
                    fullWidth
                    name="password"
                    type="password"
                    onChange={this.handleInput}
                  />

                  <Button
                    
                    style={{ alignSelf: "flex-start", backgroundColor: "#64aa37", color: "#fff" }}
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    {this.state.loading ? (
                      "Logging In..."
                    ) : this.state.error ? (
                      <span style={{ color: "#BD0600" }}>
                        Incorrect Credentials!!!
                      </span>
                    ) : (
                      " Log In"
                    )}
                  </Button>
                </form>

                <Grid
                  item
                  sm={12}
                  style={{ position: "relative" }}
                >
                  {/* <Link to="/forgot" style={{ textDecoration: "none" }}>
                    <Typography
                      
                      variant="body2"
                      style={{
                        color: "#fff",
                        alignSelf: "right",
                        cursor: "pointer",
                        display: "inline",
                      }}
                    >
                      Forgot password?
                    </Typography>
                  </Link> */}
                  {/* <Link to="/change" style={{ textDecoration: "none" }}>
                    <Typography
                      color="primary"
                      variant="body2"
                      style={{
                        alignSelf: "flex-end",
                        cursor: "pointer",
                        display: "inline",
                        marginLeft: "10px",
                      }}
                    >
                      Change password?
                    </Typography>
                  </Link> */}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
            </div>
        
        </div>
        
      </div>
    );
  }
}
