import React, { Component } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
} from "@material-ui/core";
import AccountBalanceWalletTwoToneIcon from "@material-ui/icons/AccountBalanceWalletTwoTone";

export default class CardBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboard: true,
    };
  }
  render() {
    return (
      <>
        <Grid item lg={3}>
          <Paper elevation={3}>
            <Box
              
              style={{
                backgroundColor: "#ECF0F1",
                color: "black",
                maxHeight: "90px",
                borderRadius: "5px",
                width:"100%",
                padding:"20px 11px",
              }}
            >
              <div
                className="icons"
                style={{ backgroundColor: this.props.color, height: "60px", width: "60px",  display: "flex", alignItems: "center" , justifyContent: "center", marginTop: "-40px",
                     borderRadius: "10px", color: "white", 
                }}
              >
               <span> {this.props.icon} </span>
              </div>
                <div>
                  <Typography  style={{ position: "absolute", fontSize: "15px" , color:"grey" , fontWeight:"bolder" , marginTop: "13px" }}>
                    {this.props.name}
                  </Typography>
                  <Typography align="right" style={{position: "relative", fontSize: "30px" , fontWeight:"bold", marginTop: "13px  " }}>{this.props.value}</Typography>
                </div>
               
            </Box>
          </Paper>
        </Grid>
      </>
    );
  }
}
