import {
    Backdrop,
    Button,
    Container,
    Select,
    InputLabel,
    Fade,
    FormControl,
    Grid,
    // Modal,
    Paper,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    FormControlLabel,
  } from "@material-ui/core";
  import "../../App.css";
  import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    // Table,
    Row,
    Col,
    UncontrolledAlert,
    Pagination,
    PaginationItem,
    PaginationLink,
    FormGroup,
  } from "reactstrap";
  import Modal from "react-awesome-modal";
  import renderHTML from "react-render-html";
  import { Link } from "react-router-dom";
  import axios from "axios";
  import React, { Component } from "react";
  import Constants from "../Variables/Constants";
  import init from "../../helpers/WindowToken";
  import "../../App.css";

  export default class Hiring extends Component{

    state={
        allHirings: [],
        search: "",
        designation_title: "",
        experiance_level: "",
        qualification: "",
        job_description: "",
        skillset: "",
        role: "",
        functionalArea: "",
        rowId: "",
        visible: false,
        loading: false,
        deleteHiring: false,
        notifyDelete: false,
        delMsg: "",
        delLoading: false,
    }

    componentDidMount(){
        this.getHirings()
    }

    getHirings = ()=>{
    //   if ( init() === "success" ){
        axios.get( Constants.getUrls.getAllHirings )
        .then((resp)=>{
            console.log(resp);
            this.setState({
                allHirings: resp.data.data,
                // loading: true
            }, ()=>{console.log(this.state.allHirings)})
        })
    //   }
        
    }
//  search start
    handleChange =(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        }, ()=>{
            if( this.state.search.length >=3 || this.state.search.length === 0){
                this.getHirings()
            }
        })
    }
    // search ends

    // delete starts
    handleModal =(hiring)=>{
        this.setState({
          rowId: hiring._id,
          visible: true
        })
      }
      
    handleDelete = () => {
        this.setState({
          delLoading: true
        })
        let payload = {
          _id: this.state.rowId
        }
  
        if (init() == "success") {
          // this.setState({ delLoading: true });
          axios.delete(Constants.postUrls.deleteHirings + this.state.rowId).then((res) => {
            console.log(res);
            this.setState({
              deleteHiring: true,
              delLoading: false,
              delMsg:res.data.message
            });
            setTimeout(() => {
              this.setState({ deleteHiring: false, visible: false }, () => {
                this.getHirings();
              });
            }, 2000);
          });
        }
      };

    render(){
        return(
            <Container maxWidth="xl">
                <Grid container spcaing={2}>
                    <Grid item sm={6}>
                      <Typography variant="h4">Hirings</Typography>
                    </Grid>
                    <Grid container justify="flex-end">
                        {/* <FormControl >
                            <TextField
                                id="standard-basic"
                                label="Search hiring"
                                variant="standard"
                                size="small"
                                name="search"
                                onChange={this.handleChange}
                                />
                        </FormControl> */}
                      
                                <Link
                                  to="/admin/addhirings"
                                     style={{ textDecoration: "none"}}
                                    >
                                    <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={()=>{
                                        this.setState({ open: true})
                                    }}
                                    >
                                        Create Hirings
                                    </Button>
                                </Link>
            
                    </Grid>          
                </Grid>

                <Grid sm={12}>
                  <Paper elevetion={1}>
                    <TableContainer>
                        <Table size="small" stickyHeader aria-label="a dense table">
                         <TableHead>
                            <TableRow>
                                <TableCell>Designation</TableCell>
                                <TableCell>Experiance Level</TableCell>
                                <TableCell>Qualification</TableCell>
                                <TableCell>Job Description</TableCell>
                                <TableCell>Skillsets</TableCell>
                                <TableCell>Job Role</TableCell>
                                <TableCell>Functional Area</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                         </TableHead>
                         
                         <TableBody>
                            { this.state.allHirings?.length > 0 ?
                                (this.state.allHirings?.map((hiring)=>(
                                    <TableRow  >
                                        <TableCell> {hiring.designation_title} </TableCell>
                                        <TableCell> {hiring.experiance_level}</TableCell>
                                        <TableCell> {hiring.qualification}</TableCell>
                                        <TableCell> {hiring.job_description}</TableCell>
                                        <TableCell> {hiring.skillset}</TableCell>
                                        <TableCell> {hiring.role}</TableCell>
                                        <TableCell> {hiring.functional_area}</TableCell>
                                        

                                        <TableCell>
            
                                            <Link
                                                to={{
                                                pathname: "/admin/updatehirings/" + hiring._id,
                                                state: { data: hiring },
                                                }}
                                                style={{textDecoration:"none"}}
                                             >
                                            <Button
                                                variant={"outlined"}
                                                color="primary"
                                                style={{ marginRight: 4, marginBottom: "8px" }}
                                                size={"small"}
                                              >
                                                Update
                                            </Button>
                                                </Link>
                                            <Button
                                                size={"small"}
                                                variant={"outlined"}
                                                color="secondary"
                                                onClick={() => {
                                                   this.handleModal(hiring)
                                                }}
                                                 >
                                                Delete
                                            </Button>
                                        </TableCell>

                                    </TableRow>)
                                ))
                                :(
                                    <TableRow>
                                        <TableCell>
                                            NO DATA FOUND
                                        </TableCell>
                                    </TableRow>
                                )
                            }                             
                         </TableBody>
                     
                        </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Modal
                 visible={this.state.visible}
                 onClickAway={()=> this.setState({ visible: false})}
                 width="450px"
                 height="80px"
                 effect="fadeInUp"
                 >
                     <Row>
                         <Col xs={12} xm={12} md={12}>
                          <Card>
                              <CardBody>
                                  <div className="modal">
                                      <h3 
                                        classname="changeColor" 
                                        style={{color: "black"}}
                                       > Are you sure you want to Delete??</h3>
                                      
                                      {this.state.deleteHiring ? (
                                          <Snackbar
                                           anchorOrigin={{
                                               vertical: "bottom",
                                               horizontal: "bottom"
                                           }}
                                           open={true}
                                           autoHideDuration={5000}
                                           color={"s"}
                                           message=" deleted Successfully"
                                           >
                                              
                                          </Snackbar>
                                      ) : null}

                                      <Row>
                                          <Col xs={12} xm={12} md={4}>
                                           <br />
                                           <Button 
                                                className=""
                                                color="secondary"
                                                variant="contained"
                                                style={{margin : "20px"}}
                                                onClick={() => {
                                                this.handleDelete(this.state.rowId)
                                                }}
                                                >
                                                {this.state.deleteHiring ? "Deleting Hiring" : "Yes"}
                                           </Button>
                                           <Button
                                                 className=""
                                                 variant="contained"
                                                 color="primary"
                                                 style={{ margin: "20px", marginLeft: "4px"}}
                                                 onClick={()=>{
                                                     this.setState({ visible: false})
                                                 }}
                                                >
                                                 no
                                                </Button>
                                          </Col>
                                      </Row>
                                  </div>
                              </CardBody>
                          </Card>
                         </Col>
                     </Row>
                </Modal>          
            </Container>
        )
    }

  }