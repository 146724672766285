import React, { Component } from "react";
import axios from "axios";
// import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Box,
  //   GridItem,
  Modal,
  TextField,
  Typography,
  Snackbar,
  //   InputLabel,
  //   Select,
  //   MenuItem,
} from "@material-ui/core";
import { Redirect } from "react-router-dom";
import Constants from "../App/Variables/Constants";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { Form } from "reactstrap";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { co } from "co";
import init from "../helpers/WindowToken";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { makeStyles } from "@material-ui/core";

const classes = makeStyles();

export default class UpdateHirings extends Component {
  state = {
    _id: "",
    designation_title: "",
    experiance_level: "",
    qualification: "",
    job_description: [],
    skillset: [],
    role: "",
    functional_area: "",
    loading: false,
    // notification: false,
    // redirect: false,
  };

  componentDidMount() {
    let id = window.location.pathname.split("/")[3];
    this.setState({
      _id: id,
    });

    if (init() === "success") {
      axios.get(Constants.getUrls.getHiringById + id).then((resp) => {
        console.log(resp);
        this.setState({
            designation_title: resp.data.single_data.designation_title,
            experiance_level: resp.data.single_data.experiance_level,
            qualification: resp.data.single_data.qualification,
            job_description: resp.data.single_data.job_description,
            skillset: resp.data.single_data.skillset,
            role: resp.data.single_data.role,
            functional_area: resp.data.single_data.functional_area,
        
        });
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let hiringLoad = {
      _id: this.state._id,
      designation_title: this.state.designation_title,
      experiance_level: this.state.experiance_level,
      qualification: this.state.qualification,
      job_description: this.state.job_description,
      skillset: this.state.skillset,
      role: this.state.role,
      functional_area: this.state.functional_area,
    };
    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios.put(Constants.putUrls.updateHiring + this.state._id , hiringLoad).then((resp) => {
        console.log(resp);
        this.setState({
          loading: false,
        //   notification: true,
        });
        setTimeout(() => {
          this.setState({
          //  notification: false,
            redirect: true,
          });
        }, 2000);
      });
    }
  };
  // }

  handleStart = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value + "T00:00:00.000Z",
      },
      () => {
        console.log(this.state.startDate);
      }
    );
  };
  handleEnd = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value + "T11:59:59.999Z",
      },
      () => {
        console.log(this.state.endDate);
      }
    );
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to="/hirings" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5"> Update Hiring</Typography>
            </Grid>
          </Grid>

          {/* After Getting api add Snackbar notification here  */}

          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={true}
              color={"green"}
              autoHideDuration={5000}
              message="Hirings Updated Successfully"
            ></Snackbar>
          ) : null}

          <div
            style={{
              background: "#5b5757",
              padding: "20px",
              borderRadius: "10px",
              width: "100%",
              border: "0",
              display: "flex",
              position: "relative",
              fontSize: ".875rem",
              minWidth: "0",
              wordWrap: " break-word",
              boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
              marginTop: "30px",
              borderRadius: "6px",
              marginBottom: "30px",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>

            <form onSubmit={this.handleSubmit}>
              <Grid container sm={12} spacing={2}>
                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"                 
                    onChange={this.handleInput}
                    label="Designation Name"
                    name="designation_title"
                    variant="outlined"
                    // required={true}
                    value={this.state.designation_title}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Experiance Level"
                    name="experiance_level"
                    variant="outlined"
                    // required={true}
                    style={{ marginRignt:"3px",  width: "100%",}}
                    value={this.state.experiance_level}
                  />
                </Grid>


                <Grid item sm={4}>
                 <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Qualification"
                    name="qualification"
                    variant="outlined"
                    // required={true}
                    style={{ marginRignt:"3px",  width: "100%",}}
                    value={this.state.qualification}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Job Description"
                    name="job_description"
                    variant="outlined"
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                    value={this.state.job_description}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Skillsets"
                    name="skillset"
                    variant="outlined"
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                    value={this.state.skillset}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Job Role"
                    name="role"
                    variant="outlined"
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                    value={this.state.role}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Functional Area"
                    name="functional_area"
                    variant="outlined"
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                    value={this.state.functional_area}
                  />
                </Grid>

               
              </Grid>

              {/* <div style={{ color: "black", marginLeft: "8px", width: "99%" }}>
                <label style={{ color: "white" }}> Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    this.setState({
                      description: data,
                    });
                  }}
                  value={this.state.description}
                  data={this.state.description}
                />
              </div> */}
              <br />
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  color="primary"
                  style={{
                    marginRight: "20px",
                    width: "15%",
                    marginTop: "35px",
                  }}
                >
                  {this.state.loading ? "Updating.." : "Update Hirings"}
                </Button>
                <Link
                  to={{
                    pathname: "/hirings",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </Container>
      );
    }
  }
}
