import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
// import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Box,
//   GridItem,
  Modal,
  TextField,
  Typography,
  Snackbar,
//   InputLabel,
//   Select,
//   MenuItem,
} from "@material-ui/core";
import init from "../helpers/WindowToken";
import Constants from "../App/Variables/Constants";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { makeStyles } from "@material-ui/core";



const classes = makeStyles();

  export default class AddTestimonial extends Component{
    state={

        reviews: "",
        name: "",
        position: "",
        pro_pic: "",
        // couponOffers: "",
        // description: "",
        // couponType: "",
        loading: false,
        // notification: false,
      //  time: "",
       // offerType: "",
    }

    handleSubmit = (e)=>{
        e.preventDefault();
        let payload = new FormData();
        payload.append("reviews", this.state.reviews);
        payload.append("name", this.state.name);
        payload.append("position", this.state.position);
        payload.append("pro_pic", this.state.pro_pic);
        // let TestimonialLoad ={
        //   reviews: this.state.reviews,
        //   name: this.state.name,
        //   position: this.state.position,
        //   pro_pic: URL.createObjectURL(this.state.pro_pic),
        //   // offers: this.state.couponOffers,
        //   // description: this.state.description,
        //   // offerType: this.state.offerType,
        //   // couponType: this.state.couponType,
        // }
        // console.log(TestimonialLoad)
        console.log(payload)
        this.setState({
            loading: true
        })
        if( init() === "success"){
            axios.post(Constants.postUrls.createTestimonial , payload)
            .then((resp)=>{
                console.log(resp)
                this.setState({
                    loading: false,
                    notification: true,
                    message: resp.Message,
                })
            })
            setTimeout(()=>{
                this.setState({
                    notification: false,
                    redirect: true
                })
            }, 1500)
            
        }
    }
    handleStart = (e)=>{
      this.setState({
        [e.target.name]: e.target.value + "T00:00:00.000Z",
      }, ()=>{console.log(this.state.startDate)});
    }
    handleEnd = (e)=>{
      this.setState({
        [e.target.name]: e.target.value + "T11:59:59.999Z",
      }, ()=>{console.log(this.state.endDate)});
    }
    handleInput = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      };

    handleFile = (e) => {
      if(e.target.name === "pro_pic"){ 
        this.setState({
          pro_pic: e.target.files[0]
        })
      }

      console.log(this.state.pro_pic);
    }
      

    render(){
        if ( this.state.redirect){
            return <Redirect to="/testimonials" />
        } else{
        return(
            <Container maxWidth="xl">
                <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
                    <Grid item xs={6}>
                      <Typography variant="h5"> Create Testimonials </Typography>
                    </Grid>
                </Grid>

                {/* After Getting api add Snackbar notification here  */}
                { this.state.notification ? (
                   <Snackbar
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      open={true}
                      color={"s"}
                      autoHideDuration={6000}
                      message={this.state.message}
                    ></Snackbar>

                ): null}

                <div
                  style={{
                    background: "#5b5757",
                    padding: "20px",
                    borderRadius: "10px",
                    // color: rgba(0, 0, 0, 0.87);
                    width: "100%",
                    border: "0",
                    display: "flex",
                    position: "relative",
                    fontSize: ".875rem",
                    minWidth: "0",
                    wordWrap: " break-word",
                    /* background: #FFF; */
                    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
                    marginTop: "30px",
                    borderRadius: "6px",
                    marginBottom: "30px",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                 >
                     <div
                      style={{
                        background:
                          "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                        boxShadow:
                          " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                        float: "left",
                        padding: "15px",
                        marginTop: "-43px",
                        marginRight: "15px",
                        borderRadius: "3px",
                        backgroundColor: "#999",
                        width: "6%",
                        height: "60px",
                        marginBottom: "25px",
                        textAlign: "center",
                      }}
                      >
                          <EditIcon />
                     </div>

                 <form onSubmit={this.handleSubmit} encType="multipart/form-data">

                    <Grid container sm={12} spacing={2}>
                      <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Reviews"
                            name="reviews"
                            variant="outlined"
                            required={true}
                            
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Name"
                            name="name"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Position"
                            name="position"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        <Grid item sm={4}>
                        <TextField
                            type="file"
                            id="outlined-basic"
                            onChange={this.handleFile}
                            label="Pro Pic"
                            name="pro_pic"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                             
                        </Grid>

                        {/* <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Skillsets"
                            name="skillset"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Job Role"
                            name="role"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid>

                        <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Functional Area"
                            name="functional_area"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid> */}

                        {/* <Grid item sm={4}>
                        <TextField
                            type="Number"
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Offers Amount"
                            name="couponOffers"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%",}}
                            />
                        </Grid> */}
                        
                        {/* <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            onChange={this.handleInput}
                            label="Coupon Code"
                            name="couponCode"
                            variant="outlined"
                            required={true}
                            style={{  width: "100%", }}
                            />
                        </Grid> */}

                       

                        {/* <Grid item sm={4}>
                      <FormControl
                        // style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          style={{
                            marginLeft: "15px",
                          }}
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Select Offer Type
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          id="outlined-basic"
                          variant="outlined"
                          value={this.state.offerType}
                          inputProps={{
                            name: "offerType",
                            id: "simple-select",
                            onChange: this.handleInput,
                          }}
                        >
                          <MenuItem value="amount"> Amount</MenuItem>
                          <MenuItem value="percent"> Percent </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid> */}
{/* 
                        <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            // onChange={ (e)=>{
                            //     this.setState({ winningRatio: e.target.value})
                            // }}
                            onChange={this.handleInput}
                            label="Coupon Type"
                            name="couponType"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%", marginLeft: "1px"}}
                            />
                        </Grid> */}
                        {/* <Grid item sm={4}>
                          <TextField
                              type="date"
                              id="outlined-basic"
                              // onChange={ (e)=>{
                              //     this.setState({ winningRatio: e.target.value})
                              // }}
                              onChange={this.handleStart}
                              label="Start Date"
                              name="startDate"
                              variant="outlined"
                              required={true}
                              focused
                              style={{ marginRignt:"3px",  width: "100%", marginLeft: "1px"}}
                              />
                        </Grid> */}
                        {/* <Grid item sm={4}>
                          <TextField
                              type="date"
                              id="outlined-basic"
                              // onChange={ (e)=>{
                              //     this.setState({ winningRatio: e.target.value})
                              // }}
                              onChange={this.handleEnd}
                              label="End Date"
                              name="endDate"
                              variant="outlined"
                              required={true}
                              focused
                              style={{ marginRignt:"3px",  width: "100%", marginLeft: "1px"}}
                              />
                        </Grid> */}

                        {/* <div style={{ color: "black", marginLeft: "8px", width: "99%"}}>
                           <label style={{color: "white"}}> Description</label>
                            <CKEditor
                              editor={ClassicEditor}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                // console.log({ event, editor, data });
                                this.setState({
                                  description: data,
                                });
                              }}
                              data={this.state.description}
                            />
                          </div> */}
                        

                    </Grid> 
                         
                    <br/>
                    <div>
                    <Button
                            variant="contained"
                            type="submit"
                            size="small"
                            color="primary"
                            style={{marginRight: "20px", width: "15%", marginTop: "35px" }}
                            >
                            {this.state.loading ? "Adding.." : "Add Testimonial"}
                        </Button>
                    <Link
                        to={{
                            pathname: "/testimonials",
                        }}
                        style={{ textDecoration: "none" }}
                        >
                        <Button
                            variant="contained"
                            type="submit"
                            size="small"
                            color="secondary"
                            style={{ marginRight: "20px", marginTop: "35px" }}
                           >
                            Cancel
                        </Button>
                    </Link>
                    </div>
                 </form>
                     
                </div>

            </Container>
        )
    }
  }
  }