import React, { Component } from "react";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Box,
  //   GridItem,
  Modal,
  TextField,
  Typography,
  Snackbar,
  //   InputLabel,
  //   Select,
  //   MenuItem,
} from "@material-ui/core";
import init from "../helpers/WindowToken";
import axios from "axios";
import Constants from "../App/Variables/Constants";
import { Link, Redirect } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";

export default class UpdateTraining extends Component {
  state = {
    _id: "",
    title: this.props.location.state?.data?.title,
    // description: this.props.location.state?.data?.description,
    // file: this.props.location.state?.data?.filePath,
    loading: false,
    // notification: false,
    // redirect: false,
  };

  componentDidMount() {
    // console.log("Nehal", this.props.location.state?.data);
    let id = window.location.pathname.split("/")[3];
    this.setState({
      _id: id,
    });

    if (init() === "success") {
      axios.get(Constants.getUrls.getOurTrainingById + id).then((resp) => {
        console.log(resp);
        this.setState({
          title: resp.data.data.singal_data.title,
          // description: resp.data.data.singal_data.description,
          // file: resp.data.data.singal_data.file,
        });
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let ourTrainingLoad = {
      _id: this.state._id,
      title: this.state.title,
      // description: this.state.description,
      // file: this.state.file,
    };
    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios
        .put(Constants.putUrls.updateTraining + this.state._id, ourTrainingLoad)
        .then((resp) => {
          console.log(resp);
          this.setState({
            loading: false,
            //   notification: true,
          });
          setTimeout(() => {
            this.setState({
              //  notification: false,
              redirect: true,
            });
          }, 2000);
        });
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/trainings" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5"> Update Our Training</Typography>
            </Grid>
          </Grid>

          {/* After Getting api add Snackbar notification here  */}

          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={true}
              color={"green"}
              autoHideDuration={5000}
              message="Data Updated Successfully"
              style={{ color: "black" }}
            ></Snackbar>
          ) : null}

          <div
            style={{
              background: "#5b5757",
              padding: "20px",
              borderRadius: "10px",
              width: "100%",
              border: "0",
              display: "flex",
              position: "relative",
              fontSize: ".875rem",
              minWidth: "0",
              wordWrap: " break-word",
              boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
              marginTop: "30px",
              borderRadius: "6px",
              marginBottom: "30px",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>

            <form onSubmit={this.handleSubmit}>
              <Grid container sm={12} spacing={2}>
                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Title"
                    name="title"
                    variant="outlined"
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                    defaultValue={this.props.location.state?.data?.title}
                  />
                </Grid>

                {/* <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Description"
                    name="description"
                    variant="outlined"
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                    defaultValue={this.props.location.state?.data?.description}
                  />
                </Grid> */}

                {/* <Grid item sm={4}>
                  <TextField
                    type="file"
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="File"
                    name="file"
                    variant="outlined"
                    required={true}
                    // value={this.state.file}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid> */}

                {/* <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Functional Area"
                    name="functional_area"
                    variant="outlined"
                    // required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                    value={this.state.functional_area}
                  />
                </Grid> */}
              </Grid>

              {/* <div style={{ color: "black", marginLeft: "8px", width: "99%" }}>
                <label style={{ color: "white" }}> Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    this.setState({
                      description: data,
                    });
                  }}
                  value={this.state.description}
                  data={this.state.description}
                />
              </div> */}
              <br />
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  color="primary"
                  style={{
                    marginRight: "20px",
                    width: "15%",
                    marginTop: "35px",
                  }}
                >
                  {this.state.loading ? "Updating.." : "Update Training"}
                </Button>
                <Link
                  to={{
                    pathname: "/trainings",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </Container>
      );
    }
  }
}
