import {
  Button,
  Container,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../Assets/Stocks10.svg"
import LOGOTSE from "../../Assets/tSe_logo 1.png"
import React, { Component } from "react";
import Constants from "../Variables/Constants";


export default class ForgotPassword extends Component {
  state = {
    email: "",
    loading: false,
    error: false,
    redirect: false,
    message: "",
  };

  submit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let submitLoad = {
      email: this.state.email,
    };
    console.log(submitLoad);
    // if(  !== 400){
      
      axios.post(Constants.postUrls.forgotPassword, submitLoad)
        .then((resp)=>{
          console.log(resp);
          setTimeout(() => {
            this.setState({
              notification: true,
              loading: false,
              message: resp.data.Message,
            });
          }, 2000);
          setTimeout(() => {
            this.setState({
              redirect: true
            })
          }, 4000);
        }).catch(err => {
          if (err) {
            console.log(err);
            this.setState({
            loading: false,
            message: "Invalid Credentials",
            error: true,
          });
          setTimeout(() => {
            this.setState({
              error: false,
            });
            }, 3000);
          }
        });
     
    //  }
    // //  else {
    //   // localStorage.setItem("accessToken", resp.data.user.accessToken);
    //   axios.post(Constants.postUrls.forgotPassword, submitLoad)
    //   .then((resp).catch(err => {
    //     if (err) {
    //       console.log(err);
    //     }
    //   });
    //   //  => {
    //     // console.log(resp);
    //     // if (resp.data.status === "failed") {
    //       this.setState({
    //         // loading: false,
    //         message: "Invalid Credentials",
    //         error: true,
    //       });
    //       setTimeout(() => {
    //         this.setState({
    //           error: false,
    //         });
    //       }, 3000);
         
    //     }
    //     else{
    //       console.log("hello")
    //       setTimeout(() => {
    //         this.setState({
    //           notification: true,
    //           loading: false,
    //           message: resp.data.Message,
    //         });
    //       }, 2000);
    //       setTimeout(() => {
    //         this.setState({
    //           redirect: true
    //         })
    //       }, 4000);
    //     }
      // }
      // )
    // }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    if( this.state.redirect){
      return <Redirect to="/newpassword" />
    } else{
      return (
        <div className="loginWrapper">
          <div
           style={{
            height: "100vh",
            backgroundColor: "#111",
            opacity: "0.7",
          }}
          >
            <img src={LOGOTSE}
              style={{
               marginTop: "10px",
               marginLeft: "10px",
                height: "140px",
                width: "150px",
              }}
              />
              <div>
              <Container maxWidth="md">
            <Grid container>
              <Grid item sm={6}  style={{ position: "relative", height: "80vh", marginTop: "-100px" }}>
                {/* NOTIFICATION */}
                {this.state.notification ? (
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={true}
                    color={"s"}
                    autoHideDuration={6000}
                    message={this.state.message}
                  ></Snackbar>
                ) : null}
                {/* NOTIFICATION */}
                <Paper className="loginCard" elevation={15} style={{ height: "50vh" }}>
                  <Typography variant="h4" component="h2" gutterBottom style={{color: "#64aa37", cursor:"pointer"}}>
                    TSE Dashboard
                  </Typography>
                  <Typography variant="h6" component="h1">
                    Forgot Password
                  </Typography>
  
                  {/* <Typography
                      color="primary"
                      variant="body2"
                      style={{ alignSelf: "flex-end" , cursor:"pointer" }}
                    >
                      Forgot password?
                    </Typography> */}
                  <form className="loginFormWrapper forgot" onSubmit={this.submit} style={{minHeight:"180px"}}>
                    <TextField
                      id="outlined-basic"
                      label="E-mail id"
                      variant="outlined"
                      fullWidth
                      name="email"
                      onChange={this.handleInput}
                      required
                      style={{marginBottom:"0px"}}
                    />
  
                    <Button
                      style={{display: "flex", backgroundColor: "#64aa37", color: "#fff"}}
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      {this.state.loading ? (
                        "Submitting"
                      ) : this.state.error ? (
                        <span style={{ color: "#BD0600" }}>
                          Incorrect Credentials!!!
                        </span>
                      ) : (
                        "Submit"
                      )}
                    </Button>


                    <Link
                        to={{
                            pathname: "/login",
                        }}
                        style={{ textDecoration: "none" }}
                        >

                    <Button
                      style={{ display: "flex", backgroundColor: "#64aa37", color: "#fff" }}
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      Cancel
                    </Button>
                    </Link>

                   
                    {this.state.loading ? (
                      <CircularProgress size="1.5rem" color="secondary" />
                    ) : null}

                   
                  </form>
  
                  {/* <Grid
                      item
                      sm={12}
                      style={{ position: "relative"}}
                    >
                      <Link to="/forgot" style={{ textDecoration: "none" }}>
                        <Typography
                          color="primary"
                          variant="body2"
                          style={{
                            alignSelf: "center",
                            cursor: "pointer",
                            display: "inline",
                          }}
                        >
                          Forgot password?
                        </Typography> 
                      </Link>
                      <Link to="/change" style={{ textDecoration: "none" }}>
                        <Typography
                          color="primary"
                          variant="body2"
                          style={{
                            alignSelf: "flex-end",
                            cursor: "pointer",
                            display: "inline",
                           
                          }}
                        >
                          Change password?
                        </Typography>
                      </Link>
                    </Grid> */}
                </Paper>
              </Grid>
            </Grid>
          </Container>
              </div>
  
          </div>
        
        </div>
      );
    }
    
  }
}
