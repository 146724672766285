import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
// import Constants from "../App/Variables/Constants";
import {
  Backdrop,
  Button,
  Container,
  Fade,
  Grid,
  Box,
  //   GridItem,
  Modal,
  TextField,
  Typography,
  Snackbar,
  //   InputLabel,
  //   Select,
  //   MenuItem,
} from "@material-ui/core";
import init from "../helpers/WindowToken";
import Constants from "../App/Variables/Constants";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { makeStyles } from "@material-ui/core";
import { log } from "util";

const classes = makeStyles();

export default class AddOurWorks extends Component {
  state = {
    thumbnailImg: [],
    project_title: "",
    project_description: "",
    heroImg: [],
    websnapImg: [],
    appsnapImg: [],
    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // let ourWorkLoad ={
    //     thumbnailImg: this.state.thumbnailImg,
    //     project_title: this.state.project_title,
    //     project_description: this.state.project_description,
    //     heroImg: this.state.heroImg,
    //     websnapImg: this.state.websnapImg,
    //     appsnapImg: this.state.appsnapImg,
    // }

    // console.log(ourWorkLoad)

    let payload = new FormData();
    payload.append("thumbnailImg", this.state.thumbnailImg);
    payload.append("project_title", this.state.project_title);
    payload.append("project_description", this.state.project_description);
    payload.append("heroImg", this.state.heroImg);
    payload.append("websnapImg", this.state.websnapImg);
    // payload.append("appsnapImg", this.state.appsnapImg);
    [...this.state.appsnapImg].map((image) => {
      payload.append("appsnapImg", image);
    });

    this.setState({
      loading: true,
    });
    if (init() === "success") {
      axios.post(Constants.postUrls.createOurWorksWeb, payload).then((resp) => {
        console.log(resp);
        this.setState({
          loading: false,
          notification: true,
          message: resp.Message,
        });
      });
      setTimeout(() => {
        this.setState({
          notification: false,
          redirect: true,
        });
      }, 1500);
    }
  };
  handleStart = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value + "T00:00:00.000Z",
      },
      () => {
        console.log(this.state.startDate);
      }
    );
  };
  handleEnd = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value + "T11:59:59.999Z",
      },
      () => {
        console.log(this.state.endDate);
      }
    );
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //   handleFile = (e) => {
  //     if(e.target.name === "thumbnailImg"){
  //       this.state.thumbnailImg.push(e.target.files);
  //     this.setState({
  //       thumbnailImg : this.state.thumbnailImg
  //     })
  //   }

  //   if(e.target.name === "heroImg"){
  //     console.log();
  //     this.state.heroImg.push(e.target.files);
  //     this.setState(
  //       {
  //       heroImg : this.state.heroImg
  //     },()=>{
  //       console.log(this.state.heroImg)
  //     }
  //     )
  //   }

  //   if(e.target.name === "websnapImg"){
  //     console.log(e.target.files)
  //     this.state.websnapImg.push(e.target.files);
  //     this.setState({
  //       websnapImg : this.state.websnapImg,
  //     },
  //     () => {
  //       console.log(this.state.websnapImg)
  //     })
  //   }

  //   if(e.target.name === "appsnapImg"){
  //     console.log(e.target.files);
  //     this.state.appsnapImg.push(...e.target.files);
  //     this.setState({
  //       appsnapImg : this.state.appsnapImg,
  //     },
  //     () => {
  //       console.log(this.state.appsnapImg)
  //     })
  //   }
  //   //console.log(this.state.thumbnailImg);
  // }

  handleFile = (e) => {
    if (e.target.name === "thumbnailImg") {
      this.setState({
        thumbnailImg: e.target.files[0],
      });
    }

    if (e.target.name === "heroImg") {
      this.setState({
        heroImg: e.target.files[0],
      });
    }

    if (e.target.name === "websnapImg") {
      this.setState({
        websnapImg: e.target.files[0],
      });
    }

    if (e.target.name === "appsnapImg") {
      this.setState({
        appsnapImg: e.target.files,
      });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/ourwork" />;
    } else {
      return (
        <Container maxWidth="xl">
          <Grid container style={{ margin: "10px 0 10px" }} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5"> Create Our Works</Typography>
            </Grid>
          </Grid>

          {/* After Getting api add Snackbar notification here  */}
          {this.state.notification ? (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={true}
              color={"s"}
              autoHideDuration={6000}
              message={this.state.message}
            ></Snackbar>
          ) : null}

          <div
            style={{
              background: "#5b5757",
              padding: "20px",
              borderRadius: "10px",
              // color: rgba(0, 0, 0, 0.87);
              width: "100%",
              border: "0",
              display: "flex",
              position: "relative",
              fontSize: ".875rem",
              minWidth: "0",
              wordWrap: " break-word",
              /* background: #FFF; */
              boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
              marginTop: "30px",
              borderRadius: "6px",
              marginBottom: "30px",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(60deg, rgb(128 115 115), rgb(255 250 250)) rgb(153, 153, 153))",
                boxShadow:
                  " rgb(0 0 0 / 14%) 0px 4px 20px 0px, rgb(128 120 120 1/ 40%) 0px 7px 10px -5px",
                float: "left",
                padding: "15px",
                marginTop: "-43px",
                marginRight: "15px",
                borderRadius: "3px",
                backgroundColor: "#999",
                width: "6%",
                height: "60px",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              <EditIcon />
            </div>

            <form onSubmit={this.handleSubmit} encType="multipart/form-data">
              <Grid container sm={12} spacing={2}>
                <Grid item sm={4}>
                  <TextField
                    type="file"
                    id="outlined-basic"
                    onChange={this.handleFile}
                    label="Thumbnail Image"
                    name="thumbnailImg"
                    variant="outlined"
                    required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Project Title"
                    name="project_title"
                    variant="outlined"
                    required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.handleInput}
                    label="Project Description"
                    name="project_description"
                    variant="outlined"
                    required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    type="file"
                    id="outlined-basic"
                    onChange={this.handleFile}
                    label="Hero Image"
                    name="heroImg"
                    variant="outlined"
                    required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    type="file"
                    multiple
                    id="outlined-basic"
                    onChange={this.handleFile}
                    label="Web Snapshots    "
                    name="websnapImg"
                    variant="outlined"
                    required={true}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextField
                    type="file"
                    id="outlined-basic"
                    onChange={this.handleFile}
                    label="App Snapshots"
                    name="appsnapImg"
                    variant="outlined"
                    required={true}
                    inputProps={{
                      multiple: true,
                    }}
                    style={{ marginRignt: "3px", width: "100%" }}
                  />
                </Grid>

                {/* <Grid item sm={4}>
                      <FormControl
                        // style={{ marginTop: "12px" }}
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          style={{
                            marginLeft: "15px",
                          }}
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Select Offer Type
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          id="outlined-basic"
                          variant="outlined"
                          value={this.state.offerType}
                          inputProps={{
                            name: "offerType",
                            id: "simple-select",
                            onChange: this.handleInput,
                          }}
                        >
                          <MenuItem value="amount"> Amount</MenuItem>
                          <MenuItem value="percent"> Percent </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid> */}

                {/* <Grid item sm={4}>
                        <TextField
                            id="outlined-basic"
                            // onChange={ (e)=>{
                            //     this.setState({ winningRatio: e.target.value})
                            // }}
                            onChange={this.handleInput}
                            label="Coupon Type"
                            name="couponType"
                            variant="outlined"
                            required={true}
                            style={{ marginRignt:"3px",  width: "100%", marginLeft: "1px"}}
                            />
                        </Grid> */}
                {/* <Grid item sm={4}>
                          <TextField
                               type="date"
                              id="outlined-basic"
                              // onChange={ (e)=>{
                              //     this.setState({ winningRatio: e.target.value})
                              // }}
                              onChange={this.handleStart}
                              label="Start Date"
                              name="startDate"
                              variant="outlined"
                              required={true}
                              focused
                              style={{ marginRignt:"3px",  width: "100%", marginLeft: "1px"}}
                              />
                        </Grid> */}
                {/* <Grid item sm={4}>
                          <TextField
                              type="date"
                              id="outlined-basic"
                              // onChange={ (e)=>{
                              //     this.setState({ winningRatio: e.target.value})
                              // }}
                              onChange={this.handleEnd}
                              label="End Date"
                              name="endDate"
                              variant="outlined"
                              required={true}
                              focused
                              style={{ marginRignt:"3px",  width: "100%", marginLeft: "1px"}}
                              />
                        </Grid> */}
                {/* <div style={{ color: "black", marginLeft: "8px", width: "99%"}}>
                           <label style={{color: "white"}}> Description</label>
                            <CKEditor
                              editor={ClassicEditor}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                // console.log({ event, editor, data });
                                this.setState({
                                  description: data,
                                });
                              }}
                              data={this.state.description}
                            />
                          </div> */}
              </Grid>

              <br />
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  color="primary"
                  style={{
                    marginRight: "20px",
                    width: "15%",
                    marginTop: "35px",
                  }}
                >
                  {this.state.loading ? "Adding.." : "Add Our Work"}
                </Button>
                <Link
                  to={{
                    pathname: "/ourwork",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    color="secondary"
                    style={{ marginRight: "20px", marginTop: "35px" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </Container>
      );
    }
  }
}
