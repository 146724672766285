import {
    Backdrop,
    Button,
    Container,
    Select,
    InputLabel,
    Fade,
    FormControl,
    Grid,
    // Modal,
    Paper,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    FormControlLabel,
  } from "@material-ui/core";
  import "../../App.css";
  import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    // Table,
    Row,
    Col,
    UncontrolledAlert,
    Pagination,
    PaginationItem,
    PaginationLink,
    FormGroup,
  } from "reactstrap";
  import Modal from "react-awesome-modal";
  import renderHTML from "react-render-html";
  import { Link } from "react-router-dom";
  import axios from "axios";
  import React, { Component } from "react";
  import Constants from "../Variables/Constants";
  import init from "../../helpers/WindowToken";
  import "../../App.css";

  export default class OurWorkApp extends Component{

    state={
        allOurWorkApp: [],
        search: "",
        thumbnailImg: "",
        project_title: "",
        project_description: "",
        heroImg: "",
        AppSnapImg: "",
        rowId: "",
        visible: false,
        loading: false,
        deleteOurWork: false,
        notifyDelete: false,
        delMsg: "",
        delLoading: false,
    }

    componentDidMount(){
        this.getOurWorkApp()
    }

    getOurWorkApp = ()=>{
    //   if ( init() === "success" ){
        axios.get( Constants.getUrls.getAllOurWorksApp )
        .then((resp)=>{
            console.log(resp);
            this.setState({
                allOurWorkApp: resp.data.data,
                // loading: true
            }, ()=>{console.log(this.state.allOurWorkApp)})
        })
    //   }
        
    }
//  search start
    handleChange =(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        }, ()=>{
            if( this.state.search.length >=3 || this.state.search.length === 0){
                this.getOurWorkApp()
            }
        })
    }
    // search ends

    // delete starts
    handleModal =(ourwork)=>{
        this.setState({
          rowId: ourwork._id,
          visible: true
        })
      }
      
    handleDelete = () => {
        this.setState({
          delLoading: true
        })
        let payload = {
          _id: this.state.rowId
        }
  
        if (init() == "success") {
          // this.setState({ delLoading: true });
          axios.delete(Constants.postUrls.deleteOurWorksApp + this.state.rowId).then((res) => {
            console.log(res);
            this.setState({
              deleteOurWork: true,
              delLoading: false,
              delMsg:res.data.message
            });
            setTimeout(() => {
              this.setState({ deleteOurWork: false, visible: false }, () => {
                this.getOurWorkApp();
              });
            }, 2000);
          });
        }
      };

    render(){
        return(
            <Container maxWidth="xl">
                <Grid container spcaing={2}>
                    <Grid item sm={6}>
                      <Typography variant="h4">Our Work App</Typography>
                    </Grid>
                    <Grid container justify="flex-end">
                        {/* <FormControl >
                            <TextField
                                id="standard-basic"
                                label="Search Coupons"
                                variant="standard"
                                size="small"
                                name="search"
                                onChange={this.handleChange}
                                />
                        </FormControl> */}
                        {/* { this.state.agentProfile.role === "agent" ? null : ( */}
                                <Link
                                to="/admin/addOurWorkApp"
                                style={{ textDecoration: "none"}}
                                >
                                    <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={()=>{
                                        this.setState({ open: true})
                                    }}
                                    >
                                        Add Our Work App
                                    </Button>
                                </Link>
                            {/* )} */}
                    </Grid>
                  
                </Grid>

                <Grid sm={12}>
                  <Paper elevetion={1}>
                    <TableContainer>
                        <Table size="small" stickyHeader aria-label="a dense table">
                         <TableHead>
                            <TableRow>
                                {/* <TableCell>SR.NO</TableCell> */}
                                <TableCell>Thumbnail Image</TableCell>
                                <TableCell>Project Title</TableCell>
                                <TableCell>Project Description</TableCell>
                                <TableCell>Hero Image</TableCell>
                               
                                <TableCell>App Snapshots</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                         </TableHead>
                         
                         <TableBody>
                            { this.state.allOurWorkApp.length > 0 ?
                                (this.state.allOurWorkApp.map((ourwork)=>(
                                    <TableRow  >

                                        {/* <TableCell> {index + 1} </TableCell> */}
                                        <TableCell> <img src={`https://core.thespaceelement.com/${ourwork.thumbnailImg}`} alt="pic" width="100vw" height="100vh" /></TableCell>
                                        <TableCell> {ourwork.project_title}</TableCell>
                                        <TableCell> {ourwork.project_description}</TableCell>
                                        <TableCell> <img src={`https://core.thespaceelement.com/${ourwork.heroImg}`} alt="pic" width="100vw" height="100vh" /></TableCell>


                                        {/* <TableCell> <img src={`http://localhost:6300/${ourwork.websnapImg}`} alt="pic" width="100vw" height="100vh" /></TableCell> */}
                                        {/* <TableCell>{ourwork.websnapImg.map(image => <img src={`http://localhost:6300/${image}`} alt="pic" width="50vw" height="50vh"/>)}</TableCell> */}


                                        {/* <TableCell> <img src={`http://localhost:6300/${ourwork.appsnapImg}`} alt="pic" width="100vw" height="100vh" /></TableCell> */}
                                        <TableCell>{ourwork.AppSnapImg.map(image => <img src={`https://core.thespaceelement.com/${image}`} alt="a" width="50vw" height="50vh"/> )}</TableCell>
                                        
                                        
                                        <TableCell>
                                            {/* <Link
                                                to={{
                                                pathname: "/admin/updateOurWorkWeb/" + ourwork._id,
                                                state: { data: ourwork },
                                                }}
                                                style={{textDecoration:"none"}}
                                             >
                                            <Button
                                                variant={"outlined"}
                                                color="primary"
                                                style={{ marginRight: 4, marginBottom: "8px" }}
                                                size={"small"}
                                              >
                                                Update
                                            </Button>
                                                </Link> */}
                                            <Button
                                                size={"small"}
                                                variant={"outlined"}
                                                color="secondary"
                                                onClick={() => {
                                                   this.handleModal(ourwork)
                                                }}
                                                 >
                                                Delete
                                            </Button>
                                        </TableCell>

                                    </TableRow>)
                                ))
                                :(
                                    <TableRow>
                                        <TableCell>
                                            NO DATA FOUND
                                        </TableCell>
                                    </TableRow>
                                )
                            }                             
                         </TableBody>
                     
                        </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Modal
                 visible={this.state.visible}
                 onClickAway={()=> this.setState({ visible: false})}
                 width="450px"
                 height="80px"
                 effect="fadeInUp"
                 >
                     <Row>
                         <Col xs={12} xm={12} md={12}>
                          <Card>
                              <CardBody>
                                  <div className="modal">
                                      <h3 
                                        classname="changeColor" 
                                        style={{color: "black"}}
                                       > Are you sure you want to Delete??</h3>
                                      
                                      {this.state.deleteOurWork ? (
                                          <Snackbar
                                           anchorOrigin={{
                                               vertical: "bottom",
                                               horizontal: "bottom"
                                           }}
                                           open={true}
                                           autoHideDuration={5000}
                                           color={"s"}
                                           message="data deleted Successfully"
                                           >
                                              
                                          </Snackbar>
                                      ) : null}

                                      <Row>
                                          <Col xs={12} xm={12} md={4}>
                                           <br />
                                           <Button 
                                                className=""
                                                color="secondary"
                                                variant="contained"
                                                style={{margin : "20px"}}
                                                onClick={() => {
                                                this.handleDelete(this.state.rowId)
                                                }}
                                                >
                                                {this.state.deleteOurWork ? "Deleting our work" : "Yes"}
                                           </Button>
                                           <Button
                                                 className=""
                                                 variant="contained"
                                                 color="primary"
                                                 style={{ margin: "20px", marginLeft: "4px"}}
                                                 onClick={()=>{
                                                     this.setState({ visible: false})
                                                 }}
                                                >
                                                 no
                                                </Button>
                                          </Col>
                                      </Row>
                                  </div>
                              </CardBody>
                          </Card>
                         </Col>
                     </Row>
                </Modal>

                
                
            </Container>
        )
    }

  }

