import {
  DashboardOutlined,
  Group,
  GroupAddOutlined,
  ExtensionTwoTone,
  Copyright,
  BookmarkBorderOutlined,
  EditAttributesTwoTone,
  SportsEsportsOutlined,
  PolicyOutlined,
  AddIcCallOutlined,
  HelpOutline,
  CurrencyRupee,
  NotificationImportantOutlined,
  AppsOutlined,
  Subject,
  SmartphoneOutlined,
} from "@material-ui/icons";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Collapse,
  ListSubheader,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  AccordionActions,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, ExpandMoreOutlined } from "@material-ui/icons";
import axios from "axios";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../../../routes";
import Constants from "../../Variables/Constants";

export default class Sidebar extends Component {
  state = {
    role: "",
    x: false,
    current: -1,
    filterdRoutes: [],
  };

  componentDidMount() {
    // this.getProfile();
    // getProfile = () => {
    // let filtValue = [];
    // axios.get(Constants.getUrls.getProfile).then((res) => {
    //   console.log(res);
    //   res.data.Data.accessAreas.forEach((val) => {
    //     //  console.log(val)
    //     routes.forEach((route) => {
    //       if (route.pathId === val._id) {
    //         filtValue.push(route);
    //  console.log(route)
    //  console.log(filtValue)
    //       }
    //     });
    //   });
    //   this.setState({
    //     filterdRoutes: filtValue,
    //   });
    //   // console.log(this.state.role);
    // });
    // };
    // co
  }

  changeState = (panel) => (e, newvalue) => {
    this.setState({
      current: newvalue ? panel : -1,
    });
  };

  render() {
    return (
      // <div style={{overflowY: "scroll", height: "100vh"}}>
      <List
        component="nav"
        style={{
          height: "100vh",
          borderRight: "1px solid #ddd",
          overflowY: "scroll",
        }}
      >
        {/* {routes.map((route, index) => (
          //  route.role===this.state.role ? (

          <NavLink
            to={route.path}
            key={index}
            style={{ color: "inherit", textDecoration: "none" }}
            activeClassName={"navactive"}
          >
            <ListItem button>
              <ListItemText
                children={
                  <Typography
                    variant={"body2"}
                    style={{ fontSize: 14 }}
                    color="textPrimary"
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ paddingRight: "10px" }}>
                        {" "}
                        {route.icon}{" "}
                      </span>

                      <span style={{ paddingLeft: "10px" }}>
                        {" "}
                        {route.title.toUpperCase()}{" "}
                      </span>
                    </div>
                  </Typography>
                }
                style={{ fontSize: 12 }}
              />
            </ListItem>
          </NavLink>
          //  ) : null
        ))} */}

        <Accordion
          // style={{ backgroundColor: "#303030"}}
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Contact.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion>

        <Accordion
          // style={{ backgroundColor: "#303030"}}
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Hiring.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion>

        <Accordion
          // style={{ backgroundColor: "#303030"}}
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Carriers.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion>

        <Accordion
          // style={{ backgroundColor: "#303030"}}
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.Testimonials.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion>

        <Accordion
          // style={{ backgroundColor: "#303030"}}
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.OURWEB.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion>

        <Accordion
          // style={{ backgroundColor: "#303030"}}
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.OURAPP.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion>

        <Accordion
          // style={{ backgroundColor: "#303030"}}
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.GALLARY.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion>
        <Accordion
          expanded={this.state.current === 0}
          onChange={this.changeState(0)}
        >
          {routes.TRAINIGS.map((route, index) => (
            //  route.role===this.state.role ? (
            <NavLink
              to={route.path}
              key={index}
              style={{ color: "inherit", textDecoration: "none" }}
              // activeClassName={"navactive"}
            >
              <ListItem button>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.title.toUpperCase()} />
              </ListItem>
            </NavLink>
          ))}
        </Accordion>
      </List>
      // </div>
    );
  }
}
