import {
    Backdrop,
    Button,
    Container,
    Select,
    InputLabel,
    Fade,
    FormControl,
    Grid,
    // Modal,
    Paper,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    FormControlLabel,
  } from "@material-ui/core";
  import "../../App.css";
  import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    // Table,
    Row,
    Col,
    UncontrolledAlert,
    Pagination,
    PaginationItem,
    PaginationLink,
    FormGroup,
  } from "reactstrap";
  import Modal from "react-awesome-modal";
  import renderHTML from "react-render-html";
  import { Link } from "react-router-dom";
  import axios from "axios";
  import React, { Component } from "react";
  import Constants from "../Variables/Constants";
  import init from "../../helpers/WindowToken";
  import "../../App.css";

  export default class Contact extends Component{

    state={
        allContact: [],
        search: "",
        first_name: "",
        last_name: "",
        mobile_no: "",
        email: "",
        msg_description: "",
//  for loading and modal pop up notification
        rowId: "",
        visible: false,
        loading: false,
        deleteCoupon: false,
        notifyDelete: false,
        delMsg: "",
        delLoading: false,
    }

    componentDidMount(){
        this.getContacts()
    }

    getContacts = ()=>{
    //   if ( init() === "success" ){
        axios.get( Constants.getUrls.getAllContacts )
        .then((resp)=>{
            console.log(resp);
            this.setState({
                allContact: resp?.data?.data,
            }, ()=>{console.log(this.state.allContact)})
        })
    //   }
        
    }
//  search start
    handleChange =(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        }, ()=>{
            if( this.state.search.length >=3 || this.state.search.length === 0){
                this.getContacts()
            }
        })
    }
    // search ends

    // delete starts
    handleModal =(contact)=>{
        this.setState({
          rowId: contact._id,
          visible: true
        })
      }
      
    handleDelete = () => {
        this.setState({
          delLoading: true
        })
        let payload = {
          _id: this.state.rowId
        }
  
        if (init() == "success") {
          axios.delete(Constants.postUrls.deleteContacts + this.state.rowId).then((res) => {
            console.log(res);
            this.setState({
              deleteContact: true,
              delLoading: false,
              delMsg:res.data.message
            });
            setTimeout(() => {
              this.setState({ deleteContact: false, visible: false }, () => {
                this.getContacts();
              });
            }, 2000);
          });
        }
      };

    render(){
        return(
            <Container maxWidth="xl">
                <Grid container spcaing={2}>
                    <Grid item sm={6}>
                      <Typography variant="h4"> Contacts </Typography>
                    </Grid>
                    <Grid container justify="flex-end">
                        {/* <FormControl >
                            <TextField
                                id="standard-basic"
                                label="Search Contact"
                                variant="standard"
                                size="small"
                                name="search"
                                onChange={this.handleChange}
                                />
                        </FormControl> */}
                     
                                {/* <Link
                                to="/admin/addcontact"
                                style={{ textDecoration: "none"}}
                                >
                                    <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={()=>{
                                        this.setState({ open: true})
                                    }}
                                    >
                                        Create Contact
                                    </Button>
                                </Link> */}
                            
                    </Grid>
                  
                </Grid>

                <Grid sm={12}>
                  <Paper elevetion={1}>
                    <TableContainer>
                        <Table size="small" stickyHeader aria-label="a dense table">
                         <TableHead>
                            <TableRow>
                                <TableCell>SR.NO</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Mobile Number</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                         </TableHead>
                         
                         <TableBody>
                            { this.state.allContact?.length > 0 ?
                                (this.state.allContact?.map((contact , index)=>(
                                    <TableRow  >
                                        <TableCell> {index + 1}</TableCell>
                                        <TableCell> {contact.first_name} </TableCell>
                                        <TableCell> {contact.last_name}</TableCell>
                                        <TableCell> {contact.mobile_no}</TableCell>
                                        <TableCell> {contact.email}</TableCell>
                                        <TableCell> {contact.msg_description}</TableCell>
                                    
                                        <TableCell>
                                            {/* <Link
                                                to={{
                                                pathname: "/admin/updatecoupons/" + contact._id,
                                                state: { data: contact },
                                                }}
                                                style={{textDecoration:"none"}}
                                             >
                                            <Button
                                                variant={"outlined"}
                                                color="primary"
                                                style={{ marginRight: 4, marginBottom: "8px" }}
                                                size={"small"}
                                              >
                                                Update
                                            </Button>
                                                </Link> */}
                                            <Button
                                                size={"small"}
                                                variant={"outlined"}
                                                color="secondary"
                                                onClick={() => {
                                                   this.handleModal(contact)
                                                }}
                                                 >
                                                Delete
                                            </Button>
                                        </TableCell>

                                    </TableRow>)
                                ))
                                :(
                                    <TableRow>
                                        <TableCell>
                                            NO DATA FOUND
                                        </TableCell>
                                    </TableRow>
                                )
                            }                             
                         </TableBody>
                     
                        </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Modal
                 visible={this.state.visible}
                 onClickAway={()=> this.setState({ visible: false})}
                 width="450px"
                 height="80px"
                 effect="fadeInUp"
                 >
                     <Row>
                         <Col xs={12} xm={12} md={12}>
                          <Card>
                              <CardBody>
                                  <div className="modal">
                                      <h3 
                                        classname="changeColor" 
                                        style={{color: "black"}}
                                       > Are you sure you want to Delete??</h3>
                                      
                                      {this.state.deleteContact ? (
                                          <Snackbar
                                           anchorOrigin={{
                                               vertical: "bottom",
                                               horizontal: "bottom"
                                           }}
                                           open={true}
                                           autoHideDuration={5000}
                                           color={"s"}
                                           message="deleted Successfully"
                                           >
                                              
                                          </Snackbar>
                                      ) : null}

                                      <Row>
                                          <Col xs={12} xm={12} md={4}>
                                           <br />
                                           <Button 
                                                className=""
                                                color="secondary"
                                                variant="contained"
                                                style={{margin : "20px"}}
                                                onClick={() => {
                                                this.handleDelete(this.state.rowId)
                                                }}
                                                >
                                                {this.state.deleteContact ? "Deleting Contact" : "Yes"}
                                           </Button>
                                           <Button
                                                 className=""
                                                 variant="contained"
                                                 color="primary"
                                                 style={{ margin: "20px", marginLeft: "4px"}}
                                                 onClick={()=>{
                                                     this.setState({ visible: false})
                                                 }}
                                                >
                                                 no
                                                </Button>
                                          </Col>
                                      </Row>
                                  </div>
                              </CardBody>
                          </Card>
                         </Col>
                     </Row>
                </Modal>

                
                
            </Container>
        )
    }

  }