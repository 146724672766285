import { Animation, ValueScale } from "@devexpress/dx-react-chart";
import {
  ArgumentAxis,
  Chart,
  LineSeries,
  SplineSeries,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Card,
  CardActions,
  CardContent,
  Button,
} from "@material-ui/core";
import {
  Backdrop,
  // Button,
  // Container,
  Select,
  InputLabel,
  Fade,
  FormControl,
  // Grid,
  ListItemText,
  FormLabel,
  // Modal,
  // Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  // Typography,
} from "@material-ui/core";
import {
  PersonAddOutlined,
  ArrowDownward,
  Copyright,
} from "@material-ui/icons"

import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Constants from "../Variables/Constants";
import CardBox from "../Main/Card";
import AccountBalanceWalletTwoToneIcon from "@material-ui/icons/AccountBalanceWalletTwoTone";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import TodayIcon from "@material-ui/icons/Today";

// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

import io from 'socket.io-client';

import React, { Component } from "react";
import { inherits } from "util";

const socket = io.connect('https://core.stocks10.com')
export default class Dashboard extends Component {
  state = {
    totalUsers: "",
    runningContests: "",
    totalDownloads: "",
  };

  // handleChange = (event) => {
  //   this.setState({
  //     spacing: Number(event.target.value),
  //   });
  // };

  componentDidMount() {
    this.getData();
    this.getUserCount();
    socket.on("user_count", data=>{
      this.setState({
        totalUsers: data
      },()=>{
        console.log(this.state.totalUsers)
      })
    })
  }
  getUserCount =()=>{
    axios.get(Constants.getUrls.getUserCount).then(resp =>{
        this.setState({
          totalUsers: resp.data.data.length
        })
    })

  }

  getData = () => {
    axios.get(Constants.getUrls.Dashboard).then((resp) => {
      console.log(resp);
        // this.setState({
        //   totalUsers: resp.data.Data.usersCount,
        //   runningContests: resp.data.Data.runningContests,
        //   totalDownloads: resp.data.Data.totalDownloads,
        // })
        this.setState({
          totalDownloads: "50,000",
          // totalUsers: "39,529",
          runningContests: "51"
        })
      
    });
  };

  // currency = (x) => {
  //   // console.log(x)
  //   let x1 = 
  //   new Intl.NumberFormat("en-IN", {
  //     style: "currency",
  //     currency: "INR",
  //     maximumSignificantDigits: 3,
  //   }).format(x);

  //   return x1

  // };

  render() {
    return (
      <>
        <Container maxWidth={"xl"}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h6">Dashboard</Typography>
            </Grid>
            <Grid item xs={8}></Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "30px" }}>
            <CardBox
              name={"Total Users"}
              icon={<PersonAddOutlined  size={"small"}/>}
              value={this.state.totalUsers}
              // icon={<DoneAllIcon style={{ fontSize: "30" }} />}
              color={"#6c6cff"}
              shadow={" 247, 220, 111"}
            />
            <CardBox
              name={"Downloads"}
              value={this.state.totalDownloads}
              icon={<ArrowDownward style={{ fontSize: "30" }} />}
              color={"#52BE80"}
              shadow={" 82, 190, 128"}
              />
            <CardBox
              name={"Contest Running"}
              value={this.state.runningContests}
              icon={<Copyright style={{ fontSize: "30" }} />}
              color={"#FE7E6D"}
              shadow={"  241, 148, 138 "}
            />
            {/* <CardBox
              name={"Total Commission "}
              value= {this.currency(this.state.dashboardData.total_commission_amount)}

              // value={new Intl.NumberFormat("en-IN", {
              //   style: "currency",
              //   currency: "INR",
              //   maximumSignificantDigits: 3,
              // }).format(this.state.dashboardData.total_commission_amount)}


              icon={<AccountBalanceIcon style={{ fontSize: "30" }} />}
              color={"#85C1E9"}
              shadow={"133, 193, 233"}
            /> */}
          </Grid>

          {/* <div className="cardContainer">
            <CardBox
              name={"Invoice All Count"}
              value={this.state.dashboardData.invoice_all_count}
            />
            <CardBox
              name={"Invoice Today's Count"}
              value={this.state.dashboardData.invoice_todays_count}
            />
            <CardBox
              name={"Todays Total Commission Amount"}
              value={this.state.dashboardData.todays_total_commission_amount}
            />
            <CardBox
              name={"Total Commission Amount"}
              value={this.state.dashboardData.total_commission_amount}
            />
          </div> */}

          {/* <div style={{ marginTop: "30px" }}>
            <h3 style={{ borderBottom: "2px solid white", width: "8rem" }}>
              Recent invoices
            </h3>
            <p>
              last invoice created at :{" "}
              {this.state.invoices.length > 0
                ? new Date(this.state.invoices[0].createdAt).toDateString()
                : ""}
            </p>
            <Grid item sm={12}>
              <Paper elevation={1}>
                <TableContainer>
                  <Table size="small" stickyHeader aria-label="a dense table">
                    <TableRow
                      className="tableHead"
                      style={{
                        height: "60px",
                        backgroundColor: "#85C1E9",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        Sr.No
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Contract No
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Buyer Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Seller Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Total Commission Amount
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        Total Invoice Amount
                      </TableCell>
                    </TableRow>
                    <TableBody>
                      {this.state.invoices.map((invoice, index) => (
                        <TableRow key={invoice._id} style={{ height: "70px" }}>
                          <TableCell component="th" scope="row" align="center">
                            {index + 1}
                          </TableCell>

                          <TableCell align="center">
                            {" "}
                            {invoice.contract_no}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {invoice.buyer.name}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {invoice.seller.name}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {this.currency(invoice.total_commission_amount)}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            {this.currency(invoice.total_amount)}
                            {/* &#8377;{invoice.total_amount} */}
                          {/* </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table> */}
                  {/* PAGINATION TABLE START */}

                  {/* PAGINATION TABLE END */}
                {/* </TableContainer>
              </Paper>
            </Grid>
          </div> */} 


        </Container>
      </>
    );
  }
}
