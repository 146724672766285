import {
    Backdrop,
    Button,
    Container,
    Select,
    InputLabel,
    Fade,
    FormControl,
    Grid,
    // Modal,
    Paper,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    FormControlLabel,
  } from "@material-ui/core";
//   import TablePagination from '@mui/material/TablePagination';
  import "../../App.css";
  import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    // Table,
    Row,
    Col,
    UncontrolledAlert,
    Pagination,
    PaginationItem,
    PaginationLink,
    FormGroup,
  } from "reactstrap";
  import Modal from "react-awesome-modal";
  import renderHTML from "react-render-html";
  import { Link } from "react-router-dom";
  import axios from "axios";
  import React, { Component } from "react";
  import Constants from "../Variables/Constants";
  import init from "../../helpers/WindowToken";
 
// import { DownloadTableExcel } from 'react-export-table-to-excel';
  import "../../App.css";
  let tableRef = null;
  export default class Carrier extends Component{

    state={
        allCarrier: [],
        search: "",
        position: "",
        experiance: "",
        first_name: "",
        last_name: "",
        mobile_no: "",
        email: "",
        gender: "",
        dob: "",
        current_company: "",
        current_ctc: "",
        notice_period: "",
        expected_ctc: "",
        city: "",
        address: "",
        qualification: "",
        cv_upload:"",
//  for loading and modal pop up notification
        rowId: "",
        visible: false,
        loading: false,
        deleteCarrier: false,
        notifyDelete: false,
        delMsg: "",
        delLoading: false,
        // page:0,
        // rowsPerPage:10,
        // totalPage:0
    }

    

    componentDidMount(){
        this.getCarriers()
    }

    // getCarriers = (page=0,limit=10, search="")=>{
        getCarriers = ()=>{
    //    console.log(page)
    //   if ( init() === "success" ){
    //    let queryParams = search && search.length > 0 ? `?search=${search}&page=${page+1}&limit=${limit}`:`?page=${page+1}&limit=${limit}`;
        axios.get(Constants.getUrls.getAllCarrier)
    //    axios.get(`${Constants.getUrls.getAllCarrier}${queryParams}`)
        .then((resp)=>{
            console.log(resp);
            this.setState({
                allCarrier: resp.data.data,
            //    allCarrier: resp.data.data.docs,
            //    totalPage:resp.data.data.totalDocs
                // loading: true
            }, ()=>{console.log(this.state.allCarrier)})
        })
      }
        
    // }
//  search start
    handleChange =(e)=>{
        console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        }, ()=>{
            if( this.state.search.length >=3 || this.state.search.length === 0){
                this.getCarriers()
            }
        })
    }
    // search ends

    // delete starts
    handleModal =(carrier)=>{
        this.setState({
          rowId: carrier._id,
          visible: true
        })
      }
      
    handleDelete = () => {
        this.setState({
          delLoading: true
        })
        let payload = {
          _id: this.state.rowId
        }
  
        if (init() == "success") {
          // this.setState({ delLoading: true });
          axios.delete(Constants.postUrls.deleteCarriers + this.state.rowId).then((res) => {
            console.log(res);
            this.setState({
              deleteCarrier: true,
              delLoading: false,
              delMsg:res.data.message
            });
            setTimeout(() => {
              this.setState({ deleteCarrier: false, visible: false }, () => {
                this.getCarriers();
              });
            }, 2000);
          });
        }
      };

    //   handleChangePage = (e,newPage)=>{
    //     console.log(newPage);
    //     this.setState({page:newPage});
    //     this.getCarriers(newPage,this.state.rowsPerPage);
    //   }
    //   handleChangeRowsPerPage = (e)=>{
    //     console.log(e)
    //     this.setState({rowsPerPage:(parseInt(e.target.value, 10))});
    //     this.setState({page:0});
    //     this.getCarriers(0,e.target.value)
    //   }
    

    render(){
        return(
            <Container maxWidth="xl">
                <Grid container spcaing={2}>
                    <Grid item sm={6}>
                      <Typography variant="h4">Careers</Typography>
                    </Grid>
                    <Grid container justify="flex-end">
                   
                    {/* <Button
                        variant={"outlined"}
                        // color="#1565c0"
                        style={{ marginRight: 4, marginBottom: "20px" , }}
                        size={"medium"}
                    >
                        Download Data
                    </Button> */}

                   
                
                        {/* <FormControl >
                            <TextField
                                id="standard-basic"
                                label="Search Carrier"
                                variant="standard"
                                size="small"
                                name="search"
                                onChange={this.handleChange}
                                />
                        </FormControl> */}
                      
                                {/* <Link
                                to="/admin/addcarrier"
                                style={{ textDecoration: "none"}}
                                >
                                    <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={()=>{
                                        this.setState({ open: true})
                                    }}
                                    >
                                        Create Carriers
                                    </Button>
                                </Link> */}
                            {/* )} */}
                    </Grid>
                  
                </Grid>

                <Grid sm={12}>
                  <Paper elevetion={1}>
                    <TableContainer>
                        <Table size="small" stickyHeader aria-label="a dense table" ref={tableRef}>
                         <TableHead>
                            <TableRow>
                                 <TableCell>SR.No</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Experiance</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Mobile Number</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>Date Of Birth</TableCell>
                                <TableCell>Current Company</TableCell>
                                <TableCell>Current CTC</TableCell>
                                <TableCell>Notice Period</TableCell>
                                <TableCell>Expected CTC</TableCell>
                                <TableCell>City</TableCell>
                                <TableCell>Adress</TableCell>
                                <TableCell>Qualification</TableCell>
                                <TableCell>Resume/CV</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                         </TableHead>
                         
                         <TableBody>
                            { this.state.allCarrier?.length > 0 ?
                                (this.state.allCarrier.map((carrier, index) => (
                                    <TableRow >
                                        {/* <TableCell> {this.state.allCarrier.length - index} </TableCell> */}
                                        {/* <TableCell> {this.state.page > 0? this.state.rowsPerPage + index+ 1 : index+1} </TableCell> */}
                                        <TableCell> { index + 1} </TableCell>
                                        <TableCell> {carrier.position} </TableCell>
                                        <TableCell> {carrier.experiance}</TableCell>
                                        <TableCell> {carrier.first_name}</TableCell>
                                        <TableCell> {carrier.last_name}</TableCell>
                                        <TableCell> {carrier.mobile_no}</TableCell>
                                        <TableCell> {carrier.email}</TableCell>
                                        <TableCell> {carrier.gender}</TableCell>
                                        <TableCell> {carrier.dob}</TableCell>
                                        <TableCell> {carrier.current_company}</TableCell>
                                        <TableCell> {carrier.current_ctc}</TableCell>
                                        <TableCell> {carrier.notice_period}</TableCell>
                                        <TableCell> {carrier.expected_ctc}</TableCell>
                                        <TableCell> {carrier.city}</TableCell>
                                        <TableCell> {carrier.address}</TableCell>
                                        <TableCell> {carrier.qualification}</TableCell>

                                        <TableCell> 
                                            {       
                                            <Button
                                                variant={"contained"}
                                                color="primary"
                                                size={"small"}                                              
                                                href={`https://core.thespaceelement.com${carrier.cv_upload}`} 
                                                target={"_blank"}                                       
                                                >
                                                ShowCV
                                            </Button>
                                              
                                            }
                                        </TableCell>

                                        





                                        <TableCell>
                                            {/* <Link
                                                to={{
                                                pathname: "/admin/updatecoupons/" + carrier._id,
                                                state: { data: carrier },
                                                }}
                                                style={{textDecoration:"none"}}
                                             >
                                            <Button
                                                variant={"outlined"}
                                                color="primary"
                                                style={{ marginRight: 4, marginBottom: "8px" }}
                                                size={"small"}
                                              >
                                                Update
                                            </Button>
                                                </Link> */}
                                            <Button
                                                size={"small"}
                                                variant={"outlined"}
                                                color="secondary"
                                                onClick={() => {
                                                   this.handleModal(carrier)
                                                }}
                                                 >
                                                Delete
                                            </Button>
                                        </TableCell>

                                    </TableRow>)
                                ))
                                :(
                                    <TableRow>
                                        <TableCell>
                                            NO DATA FOUND
                                        </TableCell>
                                    </TableRow>
                                )
                            }                             
                         </TableBody>
                     
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                        component="div"
                        count={this.state.totalPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        /> */}
                  </Paper>
                </Grid>

                
                <Modal
                 visible={this.state.visible}
                 onClickAway={()=> this.setState({ visible: false})}
                 width="450px"
                 height="80px"
                 effect="fadeInUp"
                 >
                     <Row>
                         <Col xs={12} xm={12} md={12}>
                          <Card>
                              <CardBody>
                                  <div className="modal">
                                      <h3 
                                        classname="changeColor" 
                                        style={{color: "black"}}
                                       > Are you sure you want to Delete??</h3>
                                      
                                      {this.state.deleteCarrier ? (
                                          <Snackbar
                                           anchorOrigin={{
                                               vertical: "bottom",
                                               horizontal: "bottom"
                                           }}
                                           open={true}
                                           autoHideDuration={5000}
                                           color={"s"}
                                           message="Carrier deleted Successfully"
                                           >
                                              
                                          </Snackbar>
                                      ) : null}

                                      <Row>
                                          <Col xs={12} xm={12} md={4}>
                                           <br />
                                           <Button 
                                                className=""
                                                color="secondary"
                                                variant="contained"
                                                style={{margin : "20px"}}
                                                onClick={() => {
                                                this.handleDelete(this.state.rowId)
                                                }}
                                                >
                                                {this.state.deleteCarrier ? "Deleting Carrier" : "Yes"}
                                           </Button>
                                           <Button
                                                 className=""
                                                 variant="contained"
                                                 color="primary"
                                                 style={{ margin: "20px", marginLeft: "4px"}}
                                                 onClick={()=>{
                                                     this.setState({ visible: false})
                                                 }}
                                                >
                                                 no
                                                </Button>
                                          </Col>
                                      </Row>
                                  </div>
                              </CardBody>
                          </Card>
                         </Col>
                     </Row>
                </Modal>

                
                
            </Container>
        )
    }

  }